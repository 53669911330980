

/*
The main SCSS file for everything, yep
*/
/*
Bootstrap custom styles
*/
blockquote,
.blockquote {
  padding-left: 1rem;
  font-size: 1.25em;
  color: #1b75bc;
  border-left: 4px solid rgba(61, 70, 79, 0.125);
}

blockquote.text-right,
.blockquote.text-right {
  padding-right: 1rem;
  padding-left: 0;
  border-left: 0;
  border-right: 4px solid rgba(61, 70, 79, 0.125);
}

blockquote > footer,
.blockquote > footer {
  display: block;
  font-size: 80%;
  color: #686F76;
}

blockquote > footer::before,
.blockquote > footer::before {
  content: "\2014 \00A0";
}

dl:last-child {
  margin-bottom: 0;
}

pre > .hljs {
  padding: 1rem;
  border-radius: 0.2rem;
}

.card > pre > .hljs {
  border-radius: 0;
}

.card > pre:last-child {
  margin-bottom: 0;
}

.card > pre:last-child > .hljs {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

/* for block of numbers */
td.hljs-ln-numbers {
  padding-right: 10px !important;
  padding-left: 5px !important;
  text-align: center;
  color: #535A62;
  background: #E9EAEB;
  vertical-align: top;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

/* for block of code */
td.hljs-ln-code {
  padding-left: 10px;
}

.embed-responsive img.embed-responsive-item {
  top: 50%;
  left: 50%;
  bottom: auto;
  height: auto;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
  max-width: 200%;
}

.table thead th, .table thead td {
  border-top-width: 0;
  border-bottom-width: 1px;
}

.table.table-bordered thead th, .table.table-bordered thead td {
  border-top-width: 1px;
}

.card > .table,
.card > .table-responsive > .table {
  margin-bottom: 0;
}

.card > .table.table-bordered thead th, .card > .table.table-bordered thead td,
.card > .table-responsive > .table.table-bordered thead th,
.card > .table-responsive > .table.table-bordered thead td {
  border-top-width: 0;
  border-bottom-width: 0;
}

.card > .table td:first-child, .card > .table th:first-child,
.card > .table-responsive > .table td:first-child,
.card > .table-responsive > .table th:first-child {
  padding-left: 1rem;
  border-left: 0;
}

.card > .table td:last-child, .card > .table th:last-child,
.card > .table-responsive > .table td:last-child,
.card > .table-responsive > .table th:last-child {
  padding-right: 1rem;
  border-right: 0;
}

.card > .table:last-child.table-bordered tr:last-child td, .card > .table:last-child.table-bordered tr:last-child th,
.card > .table-responsive:last-child > .table.table-bordered tr:last-child td,
.card > .table-responsive:last-child > .table.table-bordered tr:last-child th {
  border-bottom-width: 0;
}

.table > thead tr:first-child > th:first-child,
.table > thead tr:first-child > td:first-child,
.table > tbody:first-child tr:first-child > th:first-child,
.table > tbody:first-child tr:first-child > td:first-child {
  border-top-left-radius: 0.25rem;
}

.table > thead tr:first-child > th:last-child,
.table > thead tr:first-child > td:last-child,
.table > tbody:first-child tr:first-child > th:last-child,
.table > tbody:first-child tr:first-child > td:last-child {
  border-top-right-radius: 0.25rem;
}

.table > tfoot tr:last-child > th:first-child,
.table > tfoot tr:last-child > td:first-child,
.table > tbody:last-child tr:last-child > th:first-child,
.table > tbody:last-child tr:last-child > td:first-child {
  border-bottom-left-radius: 0.25rem;
}

.table > tfoot tr:last-child > th:last-child,
.table > tfoot tr:last-child > td:last-child,
.table > tbody:last-child tr:last-child > th:last-child,
.table > tbody:last-child tr:last-child > td:last-child {
  border-bottom-right-radius: 0.25rem;
}

@media (max-width: 575.98px) {
  .card-fluid > .table-responsive > .table > thead tr:first-child > th:first-child,
  .card-fluid > .table-responsive > .table > thead tr:first-child > td:first-child,
  .card-fluid > .table-responsive > .table > tbody:first-child tr:first-child > th:first-child,
  .card-fluid > .table-responsive > .table > tbody:first-child tr:first-child > td:first-child,
  .card-fluid > .table > thead tr:first-child > th:first-child,
  .card-fluid > .table > thead tr:first-child > td:first-child,
  .card-fluid > .table > tbody:first-child tr:first-child > th:first-child,
  .card-fluid > .table > tbody:first-child tr:first-child > td:first-child {
    border-top-left-radius: 0;
  }
  .card-fluid > .table-responsive > .table > thead tr:first-child > th:last-child,
  .card-fluid > .table-responsive > .table > thead tr:first-child > td:last-child,
  .card-fluid > .table-responsive > .table > tbody:first-child tr:first-child > th:last-child,
  .card-fluid > .table-responsive > .table > tbody:first-child tr:first-child > td:last-child,
  .card-fluid > .table > thead tr:first-child > th:last-child,
  .card-fluid > .table > thead tr:first-child > td:last-child,
  .card-fluid > .table > tbody:first-child tr:first-child > th:last-child,
  .card-fluid > .table > tbody:first-child tr:first-child > td:last-child {
    border-top-right-radius: 0;
  }
  .card-fluid > .table-responsive > .table > tfoot tr:last-child > th:first-child,
  .card-fluid > .table-responsive > .table > tfoot tr:last-child > td:first-child,
  .card-fluid > .table-responsive > .table > tbody:last-child tr:last-child > th:first-child,
  .card-fluid > .table-responsive > .table > tbody:last-child tr:last-child > td:first-child,
  .card-fluid > .table > tfoot tr:last-child > th:first-child,
  .card-fluid > .table > tfoot tr:last-child > td:first-child,
  .card-fluid > .table > tbody:last-child tr:last-child > th:first-child,
  .card-fluid > .table > tbody:last-child tr:last-child > td:first-child {
    border-bottom-left-radius: 0;
  }
  .card-fluid > .table-responsive > .table > tfoot tr:last-child > th:last-child,
  .card-fluid > .table-responsive > .table > tfoot tr:last-child > td:last-child,
  .card-fluid > .table-responsive > .table > tbody:last-child tr:last-child > th:last-child,
  .card-fluid > .table-responsive > .table > tbody:last-child tr:last-child > td:last-child,
  .card-fluid > .table > tfoot tr:last-child > th:last-child,
  .card-fluid > .table > tfoot tr:last-child > td:last-child,
  .card-fluid > .table > tbody:last-child tr:last-child > th:last-child,
  .card-fluid > .table > tbody:last-child tr:last-child > td:last-child {
    border-bottom-right-radius: 0;
  }
}

.table-fixed {
  table-layout: fixed;
  min-width: 400px;
}

.table-fixed tr > th,
.table-fixed tr > td {
  overflow: hidden;
}

.thead-dd {
  display: inline-block;
}

.thead-dd > .custom-control {
  position: absolute;
  top: .2em;
  display: inline-block;
}

@media (min-width: 768px) {
  .thead-dd > .custom-control {
    top: .125em;
  }
}

.thead-dd .thead-btn {
  padding-left: 1.5rem;
}

.col-checker {
  width: 2rem;
}

.col-checker .custom-control-nolabel {
  top: .125rem;
}

.thead-btn {
  display: inline-block;
  margin-left: -.375rem;
  padding: .125rem .375rem;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#F5F5F5));
  background: linear-gradient(180deg, #ffffff, #F5F5F5);
  border: 1px solid gainsboro;
  -webkit-box-shadow: 0 1px 0 0 rgba(61, 70, 79, 0.05);
          box-shadow: 0 1px 0 0 rgba(61, 70, 79, 0.05);
  border-radius: 0.25rem;
}

.thead-btn .custom-control {
  display: inline-block;
  padding-left: 1rem;
}

.btn {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

@media (min-width: 768px) {
  .btn {
    font-size: 0.875rem;
  }
  .btn.btn-sm {
    font-size: 0.8125rem;
    line-height: 1.6;
  }
  .btn.btn-lg {
    font-size: 1rem;
  }
}

.btn-xs {
  font-size: 0.8125rem;
  padding: .125rem .5rem;
  line-height: 1.6;
}

.btn-floated {
  position: fixed;
  right: 3.25rem;
  bottom: 2.75rem;
  width: 3.5rem;
  height: 3.5rem;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 3rem;
  border: 0;
  -webkit-transform: translate(50%, 50%);
          transform: translate(50%, 50%);
  z-index: 5;
  -webkit-box-shadow: 0 3px 5px -1px rgba(61, 70, 79, 0.2), 0 6px 10px 0 rgba(61, 70, 79, 0.14), 0 1px 18px 0 rgba(61, 70, 79, 0.12);
          box-shadow: 0 3px 5px -1px rgba(61, 70, 79, 0.2), 0 6px 10px 0 rgba(61, 70, 79, 0.14), 0 1px 18px 0 rgba(61, 70, 79, 0.12);
}

@media (min-width: 992px) {
  .btn-floated {
    right: 2rem;
  }
}

.btn-floated.btn-sm {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1rem;
  line-height: 1;
}

.btn-floated:hover, .btn-floated:focus {
  -webkit-box-shadow: 0 3px 5px -1px rgba(61, 70, 79, 0.2), 0 6px 10px 0 rgba(61, 70, 79, 0.14), 0 1px 18px 0 rgba(61, 70, 79, 0.12) !important;
          box-shadow: 0 3px 5px -1px rgba(61, 70, 79, 0.2), 0 6px 10px 0 rgba(61, 70, 79, 0.14), 0 1px 18px 0 rgba(61, 70, 79, 0.12) !important;
}

.btn-floated:active {
  -webkit-box-shadow: 0 7px 8px -4px rgba(61, 70, 79, 0.2), 0 12px 17px 2px rgba(61, 70, 79, 0.14), 0 5px 22px 4px rgba(61, 70, 79, 0.12) !important;
          box-shadow: 0 7px 8px -4px rgba(61, 70, 79, 0.2), 0 12px 17px 2px rgba(61, 70, 79, 0.14), 0 5px 22px 4px rgba(61, 70, 79, 0.12) !important;
}

.btn-primary {
  border-color: #2b5a92;
}

.btn-primary:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-primary:focus, .btn-primary.focus {
  border-color: #254e7f;
  -webkit-box-shadow: inset 0 1px 0 0 #437fc7, 0 0 0 1px #254e7f;
          box-shadow: inset 0 1px 0 0 #437fc7, 0 0 0 1px #254e7f;
}

.btn-secondary {
  border-color: #e2e2e2;
}

.btn-secondary:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-secondary:focus, .btn-secondary.focus {
  border-color: #d5d5d5;
  -webkit-box-shadow: inset 0 1px 0 0 white, 0 0 0 1px #d5d5d5;
          box-shadow: inset 0 1px 0 0 white, 0 0 0 1px #d5d5d5;
}

.btn-success {
  border-color: #007c69;
}

.btn-success:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-success:focus, .btn-success.focus {
  border-color: #006254;
  -webkit-box-shadow: inset 0 1px 0 0 #00c8ab, 0 0 0 1px #006254;
          box-shadow: inset 0 1px 0 0 #00c8ab, 0 0 0 1px #006254;
}

.btn-info {
  border-color: #015e82;
}

.btn-info:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-info:focus, .btn-info.focus {
  border-color: #014b69;
  -webkit-box-shadow: inset 0 1px 0 0 #0194ce, 0 0 0 1px #014b69;
          box-shadow: inset 0 1px 0 0 #0194ce, 0 0 0 1px #014b69;
}

.btn-warning {
  border-color: #f5b548;
}

.btn-warning:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-warning:focus, .btn-warning.focus {
  border-color: #f4ac30;
  -webkit-box-shadow: inset 0 1px 0 0 #f9d390, 0 0 0 1px #f4ac30;
          box-shadow: inset 0 1px 0 0 #f9d390, 0 0 0 1px #f4ac30;
}

.btn-danger {
  border-color: #a95292;
}

.btn-danger:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-danger:focus, .btn-danger.focus {
  border-color: #984a84;
  -webkit-box-shadow: inset 0 1px 0 0 #c485b3, 0 0 0 1px #984a84;
          box-shadow: inset 0 1px 0 0 #c485b3, 0 0 0 1px #984a84;
}

.btn-light {
  color: #686F76;
  background-color: transparent;
  border-color: transparent;
}

.btn-light:hover {
  color: #1b75bc;
  background-color: #E9EAEB;
  border-color: #E9EAEB;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-light:focus, .btn-light.focus {
  color: #1b75bc;
  background-color: #E9EAEB;
  border-color: #E9EAEB;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  color: #1b75bc;
  border-color: #dee9f5;
  background-color: #dee9f5;
  -webkit-box-shadow: 0 0 0 1px rgba(52, 108, 176, 0);
          box-shadow: 0 0 0 1px rgba(52, 108, 176, 0);
}

.btn-dark {
  border-color: #2c3339;
}

.btn-dark:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-dark:focus, .btn-dark.focus {
  border-color: #21262b;
  -webkit-box-shadow: inset 0 1px 0 0 #4e5965, 0 0 0 1px #21262b;
          box-shadow: inset 0 1px 0 0 #4e5965, 0 0 0 1px #21262b;
}

.btn-secondary {
  background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#F5F5F5));
  background: linear-gradient(to bottom, #ffffff, #F5F5F5);
  border-color: gainsboro;
  -webkit-box-shadow: inset 0 1px 0 0 #ffffff;
          box-shadow: inset 0 1px 0 0 #ffffff;
  -webkit-box-shadow: 0 1px 0 0 rgba(61, 70, 79, 0.05);
          box-shadow: 0 1px 0 0 rgba(61, 70, 79, 0.05);
}

.btn-secondary:hover {
  background: -webkit-gradient(linear, left top, left bottom, from(#F5F5F5), to(#F5F5F5));
  background: linear-gradient(to bottom, #F5F5F5, #F5F5F5);
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#ffffff));
  background: linear-gradient(to bottom, #ffffff, #ffffff);
  -webkit-box-shadow: inset 0 1px 1px 0 rgba(19, 29, 40, 0.1), inset 0 1px 4px 0 rgba(19, 29, 40, 0.2);
          box-shadow: inset 0 1px 1px 0 rgba(19, 29, 40, 0.1), inset 0 1px 4px 0 rgba(19, 29, 40, 0.2);
}

.btn-secondary:focus, .btn-secondary.focus {
  background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#F5F5F5));
  background: linear-gradient(to bottom, #ffffff, #F5F5F5);
  border-color: #1b75bc;
  -webkit-box-shadow: 0 0 0 1px #1b75bc;
          box-shadow: 0 0 0 1px #1b75bc;
}

.btn-outline-secondary {
  color: #686F76;
}

.btn-outline-secondary:hover, .btn-outline-secondary:focus {
  border-color: #555a62;
}

.btn-reset {
  padding: 0 2px;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.btn-reset:hover, .btn-reset:focus {
  color: inherit;
  text-decoration: inherit;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-reset.text-muted:hover, .btn-reset.text-muted:focus, .btn-reset.text-muted.focus, .btn-reset.text-muted.active, .btn-reset.text-muted.show {
  color: #1b75bc !important;
}

.btn-account {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  border: 0;
  -ms-flex-wrap: none;
      flex-wrap: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: none;
  color: inherit;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  cursor: pointer;
  -webkit-transition: background-color 150ms;
  transition: background-color 150ms;
  outline: 0;
}

.btn-account:hover, .btn-account:focus {
  color: inherit;
  text-decoration: none;
}

.btn-account:active, .btn-account:focus, .btn-account.focus, .btn-account.show {
  outline: 0;
}

.btn-account .account-summary {
  margin-right: 1rem;
  margin-left: .5rem;
  display: block;
  text-align: left;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
}

.btn-account .account-name,
.btn-account .account-description {
  margin: 0;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  line-height: 1rem;
}

.btn-account .account-description {
  font-size: 0.75rem;
  font-weight: 400;
  opacity: .7;
}

.btn-account .caret {
  border-top-width: 6px;
  border-right-width: 5px;
  border-left-width: 5px;
}

.btn-account .account-icon {
  position: absolute;
  right: 1rem;
  top: 50%;
  font-size: 0.875rem;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  opacity: .7;
}

.btn-account .account-icon .caret,
.btn-account .account-icon .fa-caret-down {
  -webkit-transition: -webkit-transform 200ms ease-in-out;
  transition: -webkit-transform 200ms ease-in-out;
  transition: transform 200ms ease-in-out;
  transition: transform 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
}

.btn-account[aria-expanded="true"] > .account-icon > .caret,
.btn-account[aria-expanded="true"] > .account-icon > .fa-caret-down {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.close:focus {
  outline: 0;
}

.alert {
  border-width: 3px 0 0 0;
  -webkit-box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
          box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

@media (min-width: 768px) {
  .alert {
    font-size: 0.875rem;
  }
}

.alert.has-icon {
  padding-left: 4rem;
  min-height: 4rem;
}

.card > .alert {
  border-radius: 0;
}

.alert-icon {
  position: absolute;
  left: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 2rem;
  height: 2rem;
  text-align: center;
  border-radius: 2rem;
}

.alert-icon .oi,
.alert-icon .fa {
  font-size: 1.25rem;
}

.alert-primary .alert-icon {
  color: #ffffff;
  background-color: #2f5f9a;
}

.alert-secondary .alert-icon {
  color: #1b75bc;
  background-color: #d1d2d4;
}

.alert-success .alert-icon {
  color: #ffffff;
  background-color: #038d7a;
}

.alert-info .alert-icon {
  color: #ffffff;
  background-color: #046a94;
}

.alert-warning .alert-icon {
  color: #1b75bc;
  background-color: #d3a961;
}

.alert-danger .alert-icon {
  color: #ffffff;
  background-color: #9d5f8f;
}

.alert-light .alert-icon {
  color: #1b75bc;
  background-color: #d1d2d4;
}

.alert-dark .alert-icon {
  color: #ffffff;
  background-color: #363f49;
}

.alert-secondary {
  color: #1b75bc;
  background-color: #F5F5F5;
  border-color: #686F76;
}

.alert-secondary .alert-icon {
  color: #686F76;
  background-color: #E9EAEB;
}

.alert-secondary hr {
  border-top-color: rgba(61, 70, 79, 0.125);
}

.alert-secondary .alert-link {
  color: #1b75bc;
}

.alert-light {
  border-color: #555a62;
}

@media (min-width: 768px) {
  .badge {
    font-size: 80%;
  }
}

.badge > a {
  color: inherit;
  font-weight: 600;
}

.badge > a:hover {
  color: inherit;
  text-decoration: none;
}

.badge-lg {
  padding: .375rem .5rem;
}

.badge-subtle.badge-primary {
  color: #2e609c;
  background-color: #d6e2ef;
}

.badge-subtle.badge-primary[href]:hover, .badge-subtle.badge-primary[href]:focus {
  color: #2e609c;
  text-decoration: none;
  background-color: #cfddec;
}

.badge-subtle.badge-secondary {
  color: #686F76;
  background-color: #fdfdfd;
}

.badge-subtle.badge-secondary[href]:hover, .badge-subtle.badge-secondary[href]:focus {
  color: #686F76;
  text-decoration: none;
  background-color: #f8f8f8;
}

.badge-subtle.badge-success {
  color: #008974;
  background-color: #ccece8;
}

.badge-subtle.badge-success[href]:hover, .badge-subtle.badge-success[href]:focus {
  color: #008974;
  text-decoration: none;
  background-color: #c5e9e5;
}

.badge-subtle.badge-info {
  color: #01678f;
  background-color: #cce4ee;
}

.badge-subtle.badge-info[href]:hover, .badge-subtle.badge-info[href]:focus {
  color: #01678f;
  text-decoration: none;
  background-color: #c4e0eb;
}

.badge-subtle.badge-warning {
  color: #f6ba54;
  background-color: #fdf3e2;
}

.badge-subtle.badge-warning[href]:hover, .badge-subtle.badge-warning[href]:focus {
  color: #f6ba54;
  text-decoration: none;
  background-color: #fcefd8;
}

.badge-subtle.badge-danger {
  color: #af5a98;
  background-color: #f1e1ed;
}

.badge-subtle.badge-danger[href]:hover, .badge-subtle.badge-danger[href]:focus {
  color: #af5a98;
  text-decoration: none;
  background-color: #eedae9;
}

.badge-subtle.badge-light {
  color: #1b75bc;
  background-color: #fdfdfd;
}

.badge-subtle.badge-light[href]:hover, .badge-subtle.badge-light[href]:focus {
  color: #1b75bc;
  text-decoration: none;
  background-color: #f8f8f8;
}

.badge-subtle.badge-dark {
  color: #323941;
  background-color: #d8dadc;
}

.badge-subtle.badge-dark[href]:hover, .badge-subtle.badge-dark[href]:focus {
  color: #323941;
  text-decoration: none;
  background-color: #d3d5d7;
}

.has-badge {
  position: relative;
  display: inline-block;
}

.has-badge > .badge:last-child {
  position: absolute;
  top: 0;
  left: 100%;
  -webkit-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0);
}

.has-badge > .tile:last-child,
.has-badge > .user-avatar:last-child {
  position: absolute;
  top: 0;
  left: 100%;
  -webkit-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0);
  line-height: 1.25;
  border: 3px solid #ffffff;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.has-badge > .tile-circle + .tile,
.has-badge > .tile-circle + .user-avatar,
.has-badge > .user-avatar + .tile,
.has-badge > .user-avatar + .user-avatar {
  -webkit-transform: translate3d(-75%, 0, 0);
          transform: translate3d(-75%, 0, 0);
}

legend {
  margin-bottom: 1.5rem;
  font-size: 1rem;
  font-weight: 600;
}

.form-group legend {
  margin-bottom: .5rem;
}

label > abbr {
  color: #B76BA3;
}

label > abbr,
label > .badge {
  margin-left: .125rem;
}

label > a:hover {
  text-decoration: none;
}

label.input-group-append, label.input-group-prepend {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .form-control {
    font-size: 0.875rem;
  }
  .form-control.form-control-sm {
    font-size: 0.8125rem;
    line-height: 1.6;
  }
  .form-control.form-control-lg {
    font-size: 1rem;
  }
  .form-check {
    font-size: 0.875rem;
  }
}

@media (min-width: 768px) {
  .form-group small,
  .form-group .small,
  .form-group .valid-feedback,
  .form-group .invalid-feedback {
    font-size: 85%;
  }
}

.form-control {
  -webkit-box-shadow: inset 0 1px 0 0 rgba(61, 70, 79, 0.05);
          box-shadow: inset 0 1px 0 0 rgba(61, 70, 79, 0.05);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.form-control[readonly] {
  background-color: #ffffff;
}

.form-control[readonly]:hover ~ .input-group-prepend .input-group-text {
  color: #1b75bc;
}

.form-control-reflow, .form-control-reflow:focus {
  background-color: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.form-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: .5rem;
}

.form-actions .btn + .btn,
.form-actions .btn + .btn-group,
.form-actions .btn-group + .btn,
.form-actions .btn-group + .btn-group {
  margin-left: .125rem;
}

.form-label-group {
  position: relative;
}

.form-label-group .loader {
  position: absolute;
  right: 1rem;
  top: .75rem;
  opacity: 0;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
}

.form-label-group .loader-sm {
  top: 1rem;
}

.form-label-group.has-loading .loader {
  opacity: 1;
}

.form-label-group > input,
.form-label-group > label {
  padding: 0.75rem 0.75rem;
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  line-height: 1.5;
  text-align: left;
  color: #555a62;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  -webkit-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out;
  pointer-events: none;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown):not(.placeholder-shown) {
  padding-top: calc(0.75rem + 0.75rem * (2 / 3));
  padding-bottom: calc(0.75rem / 3);
}

.form-label-group input:not(:placeholder-shown):not(.placeholder-shown) ~ label {
  padding-top: calc(0.75rem / 3);
  padding-bottom: calc(0.75rem / 3);
  font-size: .75rem;
  color: #686F76;
}

.custom-select.is-invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.was-validated .form-control:invalid:focus {
  -webkit-box-shadow: 0 0 0 1px #B76BA3;
          box-shadow: 0 0 0 1px #B76BA3;
}

.custom-select.is-valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.was-validated .form-control:valid:focus {
  -webkit-box-shadow: 0 0 0 1px #00A28A;
          box-shadow: 0 0 0 1px #00A28A;
}

.custom-control-input.is-invalid ~ .custom-control-label:before,
.was-validated .custom-control-input:invalid ~ .custom-control-label:before {
  border-color: #B76BA3;
}

.custom-control-input.is-valid ~ .custom-control-label:before,
.was-validated .custom-control-input:valid ~ .custom-control-label:before {
  border-color: #00A28A;
}

.custom-file-input.is-invalid ~ .custom-file-label::after,
.was-validated .custom-file-input:invalid ~ .custom-file-label::after {
  border-color: #B76BA3;
}

.custom-file-input.is-invalid:focus ~ .custom-file-label,
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  border-color: #B76BA3;
  -webkit-box-shadow: 0 0 0 1px #B76BA3;
          box-shadow: 0 0 0 1px #B76BA3;
}

.custom-file-input.is-invalid:focus ~ .custom-file-label::after,
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label::after {
  border-color: #B76BA3;
}

.custom-file-input.is-valid ~ .custom-file-label::after,
.was-validated .custom-file-input:valid ~ .custom-file-label::after {
  border-color: #00A28A;
}

.custom-file-input.is-valid:focus ~ .custom-file-label,
.was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  border-color: #00A28A;
  -webkit-box-shadow: 0 0 0 1px #00A28A;
          box-shadow: 0 0 0 1px #00A28A;
}

.custom-file-input.is-valid:focus ~ .custom-file-label::after,
.was-validated .custom-file-input:valid:focus ~ .custom-file-label::after {
  border-color: #00A28A;
}

@media (min-width: 768px) {
  .input-group-lg > .form-control,
  .input-group-lg > .input-group-prepend > .input-group-text,
  .input-group-lg > .input-group-append > .input-group-text,
  .input-group-lg > .input-group-prepend > .btn,
  .input-group-lg > .input-group-append > .btn {
    font-size: 1rem;
    line-height: 1.6;
  }
  .input-group-sm > .form-control,
  .input-group-sm > .input-group-prepend > .input-group-text,
  .input-group-sm > .input-group-append > .input-group-text,
  .input-group-sm > .input-group-prepend > .btn,
  .input-group-sm > .input-group-append > .btn {
    font-size: 0.8125rem;
    line-height: 1.6;
  }
  .input-group-text {
    font-size: 0.875rem;
  }
}

.input-group:not(.input-group-alt) {
  border: 1px solid #D4D5D7;
  border-radius: 0.25rem;
  background-color: #ffffff;
  -webkit-box-shadow: inset 0 1px 0 0 rgba(61, 70, 79, 0.05);
          box-shadow: inset 0 1px 0 0 rgba(61, 70, 79, 0.05);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .input-group:not(.input-group-alt) {
    -webkit-transition: none;
    transition: none;
  }
}

.input-group:not(.input-group-alt).focus {
  border-color: #1b75bc;
  -webkit-box-shadow: 0 0 0 1px #1b75bc;
          box-shadow: 0 0 0 1px #1b75bc;
  z-index: 3;
}

.input-group:not(.input-group-alt).focus .input-group-append .badge,
.input-group:not(.input-group-alt).focus .input-group-prepend .badge {
  color: #ffffff;
  background-color: #1b75bc;
}

.input-group:not(.input-group-alt) .form-control,
.input-group:not(.input-group-alt) .input-group-text {
  border: 0;
  background-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.input-group:not(.input-group-alt) .form-control:focus, .input-group:not(.input-group-alt) .form-control.focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.input-group:not(.input-group-alt) .input-group-prepend .input-group-text {
  padding-right: 1px;
}

.input-group:not(.input-group-alt) .input-group-prepend .badge,
.input-group:not(.input-group-alt) .input-group-prepend .loader {
  margin-left: .5rem;
}

.input-group:not(.input-group-alt) .input-group-append .input-group-text {
  padding-left: 1px;
}

.input-group:not(.input-group-alt) .input-group-append .badge,
.input-group:not(.input-group-alt) .input-group-append .loader {
  margin-right: .5rem;
}

.input-group:not(.input-group-alt) .input-group-append .btn,
.input-group:not(.input-group-alt) .input-group-prepend .btn {
  border: 0;
}

.input-group:not(.input-group-alt) .input-group-append .loader,
.input-group:not(.input-group-alt) .input-group-prepend .loader {
  -ms-flex-item-align: center;
      align-self: center;
}

.input-group:not(.input-group-alt) .input-group-append .badge,
.input-group:not(.input-group-alt) .input-group-prepend .badge {
  padding: .25rem .5rem;
  -ms-flex-item-align: center;
      align-self: center;
  font-size: inherit;
  color: #555a62;
  font-weight: normal;
  background: #E9EAEB;
  -webkit-transition: all 150ms;
  transition: all 150ms;
}

.input-group:not(.input-group-alt).input-group-sm {
  border-radius: 0.2rem;
}

.input-group:not(.input-group-alt).input-group-lg {
  border-radius: 0.3rem;
}

.input-group:not(.input-group-alt) .valid-feedback,
.input-group:not(.input-group-alt) .invalid-feedback {
  margin-left: .75rem;
  margin-top: -.375rem;
  margin-bottom: .25rem;
}

.input-group-alt .input-group-text {
  color: #1b75bc;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.input-group-reflow, .input-group-reflow.focus,
.input-group-reflow .form-control,
.input-group-reflow .form-control:focus,
.input-group-reflow .input-group-prepend,
.input-group-reflow .input-group-append {
  background-color: transparent !important;
  border-color: transparent !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.input-group-reflow .input-group-prepend > .input-group-text {
  padding-right: 0;
}

.input-group-reflow .input-group-append > .input-group-text {
  padding-left: 0;
}

.input-group-alt > .input-group {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group-alt > .input-group:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-alt > .input-group:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-alt > .input-group:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.input-group-prepend .custom-select,
.input-group-append .custom-select {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group > .input-group-prepend > .custom-select,
.input-group > .input-group-append:not(:last-child) > .custom-select,
.input-group > .input-group-append:last-child > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .custom-select,
.input-group > .input-group-prepend:not(:first-child) > .custom-select,
.input-group > .input-group-prepend:first-child > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-text {
  color: #555a62;
  -webkit-box-shadow: inset 0 1px 0 0 rgba(61, 70, 79, 0.05);
          box-shadow: inset 0 1px 0 0 rgba(61, 70, 79, 0.05);
}

.input-group-text > .oi {
  top: 0;
}

.has-clearable {
  position: relative;
}

.has-clearable .form-control {
  padding-right: 30px;
}

.has-clearable .close {
  margin: 0;
  position: absolute;
  top: 50%;
  right: 0;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1;
  color: #555a62;
  opacity: 0;
  z-index: 4;
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0);
  -webkit-transition: opacity 150ms;
  transition: opacity 150ms;
}

.has-clearable .close:hover {
  color: #535A62;
}

.has-clearable .close.show {
  opacity: 1;
}

@media (min-width: 768px) {
  .custom-select,
  .custom-file,
  .custom-file-input,
  .custom-file-label,
  .custom-file-label::after {
    height: 2.188rem;
  }
  .custom-control-label:empty::before, .custom-control-label:empty::after {
    top: .125rem;
  }
  .custom-control-nolabel {
    top: -.125rem;
  }
}

.custom-control-input {
  left: 0;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #1b75bc;
}

.custom-control-input:checked ~ .custom-control-label::after {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.custom-control-input:focus ~ .custom-control-label::before {
  border-color: #1b75bc;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background: #F5F5F5;
}

.custom-control-label::before, .custom-control-label::after {
  top: .125rem;
}

@media (min-width: 768px) {
  .custom-control-label::before, .custom-control-label::after {
    top: 0;
  }
}

.custom-control-label::before {
  background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#F5F5F5));
  background: linear-gradient(to bottom, #ffffff, #F5F5F5);
  border: 1px solid #D4D5D7;
  -webkit-box-shadow: 0 1px 0 0 rgba(61, 70, 79, 0.05);
          box-shadow: 0 1px 0 0 rgba(61, 70, 79, 0.05);
  pointer-events: auto;
  -webkit-user-select: auto;
     -moz-user-select: auto;
      -ms-user-select: auto;
          user-select: auto;
}

.custom-control-label::after {
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition: -webkit-transform 200ms ease;
  transition: -webkit-transform 200ms ease;
  transition: transform 200ms ease;
  transition: transform 200ms ease, -webkit-transform 200ms ease;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.custom-control-nolabel {
  margin: 0 0 0 -.125rem;
  padding-left: 0;
}

.custom-control-nolabel > .custom-control-label {
  width: 1.25rem;
  height: 1.25rem;
  color: transparent;
  overflow: hidden;
  white-space: nowrap;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.custom-control-nolabel > .custom-control-label::before, .custom-control-nolabel > .custom-control-label::after {
  top: .125rem;
  left: .125rem;
}

.custom-checkbox .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%230179A8' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M6.41 0l-.69.72-2.78 2.78-.81-.78-.72-.72-1.41 1.41.72.72 1.5 1.5.69.72.72-.72 3.5-3.5.72-.72-1.44-1.41z' transform='translate(0 1)' /%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.custom-radio .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%230179A8' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M3 0c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z' transform='translate(1 1)' /%3e%3c/svg%3e");
}

.custom-select {
  background: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='14' fill='%233D464F' viewBox='0 0 1792 1792' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1408 704q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45z'/%3e%3c/svg%3e") no-repeat right 0.75rem center, -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#F5F5F5));
  background: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='14' fill='%233D464F' viewBox='0 0 1792 1792' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1408 704q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45z'/%3e%3c/svg%3e") no-repeat right 0.75rem center, linear-gradient(to bottom, #ffffff, #F5F5F5);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-select {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-select-reflow {
  color: #686F76;
}

.custom-select-reflow, .custom-select-reflow:focus {
  background: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='14' fill='%233D464F' viewBox='0 0 1792 1792' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1408 704q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45z'/%3e%3c/svg%3e") no-repeat right 0.75rem center;
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.custom-select-reflow:hover, .custom-select-reflow:active, .custom-select-reflow:focus {
  color: #1b75bc;
}

.custom-select-sm {
  padding: 0.25rem 0.5rem;
  height: auto;
  font-size: 0.875rem;
  line-height: 1.6;
}

@media (min-width: 768px) {
  .custom-select-sm {
    font-size: .8125rem;
  }
}

.custom-select-lg {
  padding: 0.5rem 1rem;
  height: auto;
  font-size: 1.25rem;
}

@media (min-width: 768px) {
  .custom-select-lg {
    font-size: 1rem;
  }
}

.custom-file-sm,
.custom-file-sm .custom-file-input,
.custom-file-sm .custom-file-label,
.custom-file-sm .custom-file-label::after {
  padding: 0.25rem 0.5rem;
  height: auto;
  font-size: 0.875rem;
  line-height: 1.6;
}

@media (min-width: 768px) {
  .custom-file-sm,
  .custom-file-sm .custom-file-input,
  .custom-file-sm .custom-file-label,
  .custom-file-sm .custom-file-label::after {
    font-size: .8125rem;
  }
}

.custom-file-lg,
.custom-file-lg .custom-file-input,
.custom-file-lg .custom-file-label,
.custom-file-lg .custom-file-label::after {
  padding: 0.5rem 1rem;
  height: auto;
  font-size: 1.25rem;
}

@media (min-width: 768px) {
  .custom-file-lg,
  .custom-file-lg .custom-file-input,
  .custom-file-lg .custom-file-label,
  .custom-file-lg .custom-file-label::after {
    font-size: 1rem;
  }
}

.custom-file-input {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #1b75bc;
  -webkit-box-shadow: 0 0 0 1px #1b75bc;
          box-shadow: 0 0 0 1px #1b75bc;
}

.custom-file-input:focus ~ .custom-file-label::after {
  border-color: #1b75bc;
}

.custom-file-label {
  -webkit-box-shadow: inset 0 1px 0 0 rgba(61, 70, 79, 0.05);
          box-shadow: inset 0 1px 0 0 rgba(61, 70, 79, 0.05);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.custom-file-label::after {
  background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#F5F5F5));
  background: linear-gradient(to bottom, #ffffff, #F5F5F5);
}

.list-group {
  border: 0;
  -webkit-box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
          box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
}

.list-group-flush, .list-group-reflow {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.list-group-reflow .list-group-header,
.list-group-reflow .list-group-item {
  background-color: transparent;
}

.list-group-fluid .list-group-item {
  padding-right: 0;
  padding-left: 0;
}

.list-group-header {
  margin: 0;
  padding: 0.75rem 1rem 0.75rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.75rem;
  font-weight: 600;
  color: #686F76;
  background-color: #ffffff;
}

.list-group-header:not(:first-child) {
  margin-top: 1px;
  border-top: 1px solid rgba(19, 29, 40, 0.125);
}

.list-group-header + .list-group-item .list-group-item-body,
.list-group-header + .list-group-item .list-group-item-figure {
  border-top-color: transparent !important;
}

.list-group-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  border-width: 1px 0;
  border-color: transparent;
}

.list-group-item:first-child {
  border-width: 0 0 1px;
}

.list-group-item:last-child {
  border-width: 1px 0 0;
}

.list-group-item.active {
  color: #1b75bc;
  border-color: transparent;
  border-radius: 0 !important;
  -webkit-box-shadow: inset 4px 0 0 0 #1b75bc;
          box-shadow: inset 4px 0 0 0 #1b75bc;
}

.list-group-item.active .list-group-item-figure {
  color: #1b75bc;
}

.list-group-item.active .list-group-item-text {
  color: #1b75bc;
}

.list-group-item.active:hover .list-group-item-figure, .list-group-item.active:focus .list-group-item-figure {
  color: #1b75bc;
}

.list-group-item-action:hover .list-group-item-figure, .list-group-item-action:focus .list-group-item-figure {
  color: #686F76;
}

.list-group-item-action:hover .list-group-item-text, .list-group-item-action:focus .list-group-item-text {
  color: #1b75bc;
}

.list-group-item-figure {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #555a62;
}

.list-group-item-figure:first-child {
  padding-right: 1rem;
}

.list-group-item-figure:last-child {
  padding-left: 1rem;
}

.list-group-item-body {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  min-width: 0;
  -ms-flex-item-align: center;
      align-self: center;
  font-size: 0.875rem;
}

.list-group-item-title {
  margin: 0;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: normal;
  color: #1b75bc;
}

.list-group-item-subtitle {
  margin: 0;
  font-size: inherit;
  line-height: 1.25rem;
  font-weight: normal;
  color: #1b75bc;
}

a.list-group-item-title,
a.list-group-item-subtitle,
.list-group-item-title > a,
.list-group-item-subtitle > a {
  color: #3D464F;
}

a.list-group-item-title:hover, a.list-group-item-title:focus,
a.list-group-item-subtitle:hover,
a.list-group-item-subtitle:focus,
.list-group-item-title > a:hover,
.list-group-item-title > a:focus,
.list-group-item-subtitle > a:hover,
.list-group-item-subtitle > a:focus {
  color: #1b75bc;
  text-decoration: none;
}

.list-group-item-text {
  margin-bottom: 0px;
  line-height: 1.25rem;
  color: #686F76;
}

.list-group-item.custom-control .custom-control-label {
  width: 100%;
}

.list-group-item.custom-control .custom-control-label:after,
.list-group-item.custom-control .custom-control-label:before {
  top: .125rem;
  right: .125rem;
  left: auto;
}

.list-group-item.custom-select {
  height: auto;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 0;
}

.list-group-item.custom-select:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.list-group-item-primary,
.list-group-item-secondary,
.list-group-item-success,
.list-group-item-danger,
.list-group-item-warning,
.list-group-item-info,
.list-group-item-light,
.list-group-item-dark {
  color: #1b75bc;
  background-color: #ffffff;
  border-color: rgba(19, 29, 40, 0.125);
  border-radius: 0 !important;
}

.list-group-item-primary.list-group-item-action,
.list-group-item-secondary.list-group-item-action,
.list-group-item-success.list-group-item-action,
.list-group-item-danger.list-group-item-action,
.list-group-item-warning.list-group-item-action,
.list-group-item-info.list-group-item-action,
.list-group-item-light.list-group-item-action,
.list-group-item-dark.list-group-item-action {
  color: #1b75bc;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #1b75bc;
  background-color: #F5F5F5;
}

.list-group-item-primary.list-group-item-action.active,
.list-group-item-secondary.list-group-item-action.active,
.list-group-item-success.list-group-item-action.active,
.list-group-item-danger.list-group-item-action.active,
.list-group-item-warning.list-group-item-action.active,
.list-group-item-info.list-group-item-action.active,
.list-group-item-light.list-group-item-action.active,
.list-group-item-dark.list-group-item-action.active {
  border-color: rgba(19, 29, 40, 0.125);
  border-radius: 0 !important;
  -webkit-box-shadow: inset 4px 0 0 0 #1b75bc;
          box-shadow: inset 4px 0 0 0 #1b75bc;
}

.list-group-item-primary {
  -webkit-box-shadow: inset 4px 0 0 0 #1b75bc;
          box-shadow: inset 4px 0 0 0 #1b75bc;
}

.list-group-item-secondary {
  -webkit-box-shadow: inset 4px 0 0 0 #F5F5F5;
          box-shadow: inset 4px 0 0 0 #F5F5F5;
}

.list-group-item-success {
  -webkit-box-shadow: inset 4px 0 0 0 #00A28A;
          box-shadow: inset 4px 0 0 0 #00A28A;
}

.list-group-item-info {
  -webkit-box-shadow: inset 4px 0 0 0 #0179A8;
          box-shadow: inset 4px 0 0 0 #0179A8;
}

.list-group-item-warning {
  -webkit-box-shadow: inset 4px 0 0 0 #F7C46C;
          box-shadow: inset 4px 0 0 0 #F7C46C;
}

.list-group-item-danger {
  -webkit-box-shadow: inset 4px 0 0 0 #B76BA3;
          box-shadow: inset 4px 0 0 0 #B76BA3;
}

.list-group-item-light {
  -webkit-box-shadow: inset 4px 0 0 0 #F5F5F5;
          box-shadow: inset 4px 0 0 0 #F5F5F5;
}

.list-group-item-dark {
  -webkit-box-shadow: inset 4px 0 0 0 #3D464F;
          box-shadow: inset 4px 0 0 0 #3D464F;
}

.list-group-bordered .list-group-header {
  border-top: 0;
}

.list-group-bordered .list-group-item {
  border-color: rgba(19, 29, 40, 0.125);
}

.list-group-divider .list-group-item {
  margin-top: 0;
  padding: 0;
  border-bottom: 0;
}

.list-group-divider .list-group-item.active {
  border-color: transparent;
}

.list-group-divider .list-group-item:not(:first-child) > .list-group-item-body,
.list-group-divider .list-group-item:not(:first-child) > .list-group-item-figure:last-child {
  border-top: 1px solid rgba(19, 29, 40, 0.125);
}

.list-group-divider .list-group-item-figure {
  padding: 0.75rem 1rem;
}

.list-group-divider .list-group-item-body {
  padding: 0.75rem 0;
}

.list-group-divider .list-group-item-body:first-child {
  padding-left: 1rem;
}

.list-group-divider .list-group-item-body:last-child {
  padding-right: 1rem;
}

.list-group-media {
  background-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 0;
}

.list-group-media .list-group-item {
  margin-bottom: 0;
  padding: 0;
  border: 0;
  background-color: #ffffff;
  border-radius: 0.25rem;
  -webkit-box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
          box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
}

.list-group-media .list-group-item:not(:first-child) {
  margin-top: 1rem;
}

.list-group-media .list-group-item-action:hover, .list-group-media .list-group-item-action:focus {
  background-color: #ffffff;
}

.list-group-media .list-group-item-figure {
  position: relative;
  padding: 0;
  width: 12rem;
  max-width: 40%;
  overflow: hidden;
}

.list-group-media .list-group-item-figure > img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
  max-width: 200%;
}

.list-group-media .list-group-item-body {
  padding: 1.5rem 1rem;
}

.list-group-media .list-group-item-title {
  margin-bottom: .5rem;
  font-weight: bold;
}

.card-deck {
  margin-bottom: 20px;
}

.card {
  margin-bottom: 20px;
  border: none;
  -webkit-box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
          box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
}

@media (max-width: 575.98px) {
  .card-fluid {
    border-radius: 0;
    border-width: 1px 0;
  }
  .card-fluid .card-img-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-fluid .card-img-bottom {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.card-reflow {
  margin-bottom: 0;
  display: block;
  background-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.card-header-tabs {
  margin-top: -1rem;
  font-weight: normal;
}

.card-header-control,
.card-title-control {
  margin: -.5rem 0;
}

.card-header {
  font-weight: 600;
}

.card-header-fluid {
  padding-bottom: 0;
  border-bottom: 0;
}

.card-title {
  font-size: 1rem;
}

.card-title > a {
  color: inherit;
}

.card-title > a:hover, .card-title > a:focus {
  color: inherit;
  text-decoration: none;
}

.card-subtitle {
  font-size: 0.875rem;
  font-weight: normal;
}

@media (min-width: 768px) {
  .card-subtitle {
    font-size: 0.75rem;
  }
}

.card-footer {
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}

.card-footer-content {
  padding: 1rem 1rem;
  width: 100%;
}

.card-footer-item {
  padding: 1rem 1rem;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: center;
}

.card-footer-item:hover, .card-footer-item:focus {
  text-decoration: none;
}

.card-footer-item-bordered:not(:last-child) {
  border-right: 1px solid rgba(19, 29, 40, 0.125);
}

.card-footer-item > .metric {
  padding: 0;
}

a.card-footer-item.text-muted:hover, a.card-footer-item.text-muted:focus {
  color: #3D464F !important;
}

.card-expansion {
  margin-bottom: 1rem;
}

.card-expansion-item {
  margin-bottom: 0;
  border-radius: 0;
  -webkit-transition: margin 200ms;
  transition: margin 200ms;
}

.card-expansion-item.expanded {
  margin: 1rem 0;
}

.card-expansion-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card-expansion-item:first-child.expanded {
  margin-top: 0;
}

.card-expansion-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-expansion-item:last-child.expanded {
  margin-bottom: 0;
}

.card-expansion-item:not(.expanded) + .card-expansion-item:not(.expanded) {
  margin-top: -1px;
}

[data-toggle="collapse"] .collapse-indicator {
  text-align: left;
  color: #535A62;
}

[data-toggle="collapse"] .collapse-indicator > .fa,
[data-toggle="collapse"] .collapse-indicator > .oi {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: -webkit-transform 200ms;
  transition: -webkit-transform 200ms;
  transition: transform 200ms;
  transition: transform 200ms, -webkit-transform 200ms;
}

[data-toggle="collapse"][aria-expanded="true"] .collapse-indicator > .fa-plus:before {
  content: '\f068';
}

[data-toggle="collapse"][aria-expanded="true"] .collapse-indicator > .oi-plus:before {
  content: '\e09d';
}

[data-toggle="collapse"][aria-expanded="true"] .collapse-indicator > .fa-angle-down,
[data-toggle="collapse"][aria-expanded="true"] .collapse-indicator > .fa-caret-down,
[data-toggle="collapse"][aria-expanded="true"] .collapse-indicator > .fa-chevron-down,
[data-toggle="collapse"][aria-expanded="true"] .collapse-indicator > .oi-caret-down,
[data-toggle="collapse"][aria-expanded="true"] .collapse-indicator > .oi-chevron-down {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

[data-toggle="collapse"][aria-expanded="true"] .collapse-indicator > .fa-angle-left,
[data-toggle="collapse"][aria-expanded="true"] .collapse-indicator > .fa-caret-left,
[data-toggle="collapse"][aria-expanded="true"] .collapse-indicator > .fa-chevron-left,
[data-toggle="collapse"][aria-expanded="true"] .collapse-indicator > .oi-caret-left,
[data-toggle="collapse"][aria-expanded="true"] .collapse-indicator > .oi-chevron-left {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

[data-toggle="collapse"][aria-expanded="true"] .collapse-indicator > .fa-angle-right,
[data-toggle="collapse"][aria-expanded="true"] .collapse-indicator > .fa-caret-right,
[data-toggle="collapse"][aria-expanded="true"] .collapse-indicator > .fa-chevron-right,
[data-toggle="collapse"][aria-expanded="true"] .collapse-indicator > .oi-caret-right,
[data-toggle="collapse"][aria-expanded="true"] .collapse-indicator > .oi-chevron-right {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

[data-toggle="collapse"][aria-expanded="true"] .collapse-indicator > .fa-circle,
[data-toggle="collapse"][aria-expanded="true"] .collapse-indicator > .fa-dot-circle {
  color: #1b75bc;
}

[data-toggle="collapse"][aria-expanded="true"] .collapse-indicator > .fa-circle::before,
[data-toggle="collapse"][aria-expanded="true"] .collapse-indicator > .fa-dot-circle::before {
  content: '\f192';
}

.dropdown-menu {
  margin-top: 10px;
  border: 0;
  -webkit-box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 2px 7px 1px rgba(61, 70, 79, 0.16);
          box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 2px 7px 1px rgba(61, 70, 79, 0.16);
  -webkit-animation-duration: 200ms;
          animation-duration: 200ms;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

@media (min-width: 768px) {
  .dropdown-menu {
    font-size: 0.875rem;
  }
}

.dropdown-menu.show {
  -webkit-animation-name: showDropdown;
          animation-name: showDropdown;
}

.dropdown-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.dropdown-header > a,
.dropdown-header > a:hover,
.dropdown-header > a:focus {
  font-weight: normal;
  font-size: 0.75rem;
  color: #686F76;
}

.dropdown-icon {
  margin-right: .25rem;
  color: #555a62;
  width: 1.25rem;
}

.dropdown-item:hover .dropdown-icon,
.dropdown-item:hover .subject,
.dropdown-item:hover.unread .subject,
.dropdown-item:hover .text,
.dropdown-item:hover.unread .text,
.dropdown-item:hover .date, .dropdown-item:focus .dropdown-icon,
.dropdown-item:focus .subject,
.dropdown-item:focus.unread .subject,
.dropdown-item:focus .text,
.dropdown-item:focus.unread .text,
.dropdown-item:focus .date, .dropdown-item.focus .dropdown-icon,
.dropdown-item.focus .subject,
.dropdown-item.focus.unread .subject,
.dropdown-item.focus .text,
.dropdown-item.focus.unread .text,
.dropdown-item.focus .date, .dropdown-item:active .dropdown-icon,
.dropdown-item:active .subject,
.dropdown-item:active.unread .subject,
.dropdown-item:active .text,
.dropdown-item:active.unread .text,
.dropdown-item:active .date, .dropdown-item.active .dropdown-icon,
.dropdown-item.active .subject,
.dropdown-item.active.unread .subject,
.dropdown-item.active .text,
.dropdown-item.active.unread .text,
.dropdown-item.active .date {
  color: inherit;
}

.dropdown-item.unread .subject,
.dropdown-item.unread .text {
  font-weight: 600;
  color: #1b75bc;
}

.dropdown-item .subject,
.dropdown-item .text,
.dropdown-item .date {
  margin-bottom: 0;
  line-height: 1.4;
}

.dropdown-item .subject {
  font-size: 0.75rem;
  color: #3D464F;
}

.dropdown-item .text {
  font-weight: 500;
  font-size: 0.875rem;
  color: #3D464F;
}

.dropdown-item .date {
  font-size: 0.75rem;
  color: #686F76;
}

.dropdown .dropdown-arrow,
.btn-group .dropdown-arrow {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  overflow: hidden;
  height: 1.25rem;
  width: 1.25rem;
  margin-top: -10px;
  margin-left: -10px;
  pointer-events: none;
  z-index: 1001;
}

.dropdown .dropdown-arrow-right,
.btn-group .dropdown-arrow-right {
  left: auto;
  right: 1rem;
}

.dropdown .dropdown-arrow-left,
.btn-group .dropdown-arrow-left {
  left: 2rem;
}

.dropdown .dropdown-arrow::before,
.btn-group .dropdown-arrow::before {
  content: '';
  position: absolute;
  bottom: -.375rem;
  left: 50%;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  display: block;
  height: .75rem;
  width: .75rem;
  margin-left: -5px;
  background-color: rgba(255, 255, 255, 0.98);
  -webkit-box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 2px 7px 1px rgba(61, 70, 79, 0.16);
          box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 2px 7px 1px rgba(61, 70, 79, 0.16);
  pointer-events: none;
}

.dropdown.show > .dropdown-arrow,
.btn-group.show > .dropdown-arrow {
  display: block;
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  -webkit-animation: showArrow 300ms ease-in-out;
          animation: showArrow 300ms ease-in-out;
}

.dropdown-sheets {
  padding: 1.5rem 1.5rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
}

.dropdown-sheets .dropdown-sheet-item {
  margin-bottom: 1.5rem;
  color: #686F76;
  width: calc(100%/3);
}

.dropdown-sheets .dropdown-sheet-item:nth-child(3n+1) {
  text-align: left;
}

.dropdown-sheets .dropdown-sheet-item:nth-child(3n+2) {
  text-align: center;
}

.dropdown-sheets .dropdown-sheet-item:nth-child(3n+3) {
  text-align: right;
}

.dropdown-sheets .dropdown-sheet-item:hover, .dropdown-sheets .dropdown-sheet-item:focus {
  text-decoration: none;
}

.dropdown-menu-rich {
  padding: 0;
  width: 320px;
}

.dropdown-menu-rich .dropdown-header {
  padding-top: .75rem;
  padding-bottom: .75rem;
  border-bottom: 1px solid rgba(61, 70, 79, 0.125);
}

.dropdown-menu-rich .dropdown-item {
  padding-top: .75rem;
  padding-bottom: .75rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start;
}

.dropdown-menu-rich .dropdown-item:not(:first-child) {
  border-top: 1px solid rgba(61, 70, 79, 0.125);
}

.dropdown-menu-rich .dropdown-item-body {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  white-space: normal;
  min-width: 0;
}

.dropdown-menu-rich .dropdown-item-body:not(:first-child) {
  padding-left: 1rem;
}

.dropdown-menu-rich .dropdown-item-body:not(:last-child) {
  padding-right: 1rem;
}

.dropdown-menu-rich .dropdown-icon {
  margin-right: 0;
}

.dropdown-scroll {
  position: relative;
  overflow-y: auto;
  height: 263px;
}

.dropdown-footer {
  margin: 0;
  display: block;
  padding: 0.75rem 1rem;
  font-size: 0.875rem;
  text-align: center;
  border-top: 1px solid rgba(61, 70, 79, 0.125);
}

.dropdown-footer:hover, .dropdown-footer:focus {
  text-decoration: none;
  background-color: #F5F5F5;
}

@-webkit-keyframes showDropdown {
  from {
    top: 100%;
    opacity: 0;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  100% {
    top: 100%;
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes showDropdown {
  from {
    top: 100%;
    opacity: 0;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  100% {
    top: 100%;
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes showArrow {
  from {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes showArrow {
  from {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.progress {
  font-weight: 600;
}

.card > .progress {
  border-radius: 0;
}

.progress-xs {
  height: 3px;
}

.progress-sm {
  height: .5rem;
}

.progress-lg {
  height: 1.5rem;
}

.breadcrumb-item > a {
  color: inherit;
  border-radius: 1px;
}

.breadcrumb-item > a:hover {
  text-decoration: none;
}

.breadcrumb-item > a:focus, .breadcrumb-item > a.focus, .breadcrumb-item > a:active {
  color: #1b75bc;
  background-color: #dee9f5;
  -webkit-box-shadow: 0 0 0 4px #dee9f5, 0 0 0 5px rgba(245, 245, 245, 0.5);
          box-shadow: 0 0 0 4px #dee9f5, 0 0 0 5px rgba(245, 245, 245, 0.5);
  outline: 0;
}

.breadcrumb-item.active > a:hover {
  color: #1b75bc;
  text-decoration: none;
}

.page-link {
  font-weight: 600;
}

.page-link:focus {
  color: #1b75bc;
}

.nav-link {
  outline: 0;
  color: #686F76;
}

.active > .nav-link, .nav-link.active {
  color: #1b75bc;
}

.nav-link.has-badge > .badge {
  position: absolute;
  top: .25rem;
  left: calc(100% - 1.25rem);
}

.nav-link:hover, .nav-link:focus {
  color: #1b75bc;
}

@media (min-width: 768px) {
  .nav-link {
    font-size: 0.875rem;
  }
}

.nav-tabs .nav-link {
  padding: 1rem;
  border-width: 0 0 3px;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  color: #1b75bc;
}

.nav-tabs.flex-column .nav-link {
  border-width: 0 0 0 3px;
  border-radius: 0;
}

.nav-tabs .dropdown-menu {
  margin-top: 10px;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 58px;
  overflow-y: hidden;
}

@media (min-width: 768px) {
  .nav-scroller {
    height: 55px;
  }
}

.nav-scroller > .nav {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.nav-scroller > .nav:not(.nav-tabs) {
  margin-top: .5rem;
}

@media (min-width: 768px) {
  .nav-scroller > .nav:not(.nav-tabs) {
    margin-top: calc(.625rem + 1px);
  }
}

.nav-center {
  display: block;
  text-align: center;
}

.nav-center > .nav-item,
.nav-center > .nav-link {
  display: inline-block;
}

.nav-item.has-notified,
.nav-link.has-notified {
  position: relative;
}

.nav-item.has-notified::before,
.nav-link.has-notified::before {
  position: absolute;
  top: .5rem;
  right: .75rem;
  content: '';
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: #F7C46C;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.modal.modal-alert .modal-dialog {
  opacity: 0;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  -webkit-transition: opacity 150ms ease-in-out, -webkit-transform 150ms ease-in-out;
  transition: opacity 150ms ease-in-out, -webkit-transform 150ms ease-in-out;
  transition: transform 150ms ease-in-out, opacity 150ms ease-in-out;
  transition: transform 150ms ease-in-out, opacity 150ms ease-in-out, -webkit-transform 150ms ease-in-out;
}

.modal.modal-alert.show .modal-dialog {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
}

.modal-dialog {
  margin-top: 4rem;
}

.modal-dialog-centered {
  margin: 0 auto;
}

.modal-header,
.modal-footer {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}

.modal-body {
  padding-top: 2px;
  padding-bottom: 2px;
}

@media (min-width: 576px) {
  .modal-dialog:not(.modal-dialog-centered) {
    margin-top: 5.25rem;
  }
}

.modal-dialog-overflow .modal-content {
  max-height: calc(100vh - 7.5rem);
}

.modal-dialog-overflow .modal-body {
  overflow-y: auto;
}

.modal-dialog-overflow .modal-header,
.modal-dialog-overflow .modal-footer {
  z-index: 2;
}

.modal-dialog-overflow .modal-header {
  -webkit-box-shadow: 0 2px 0 0 rgba(61, 70, 79, 0.125);
          box-shadow: 0 2px 0 0 rgba(61, 70, 79, 0.125);
}

.modal-dialog-overflow .modal-footer {
  -webkit-box-shadow: 0 -2px 0 0 rgba(61, 70, 79, 0.125);
          box-shadow: 0 -2px 0 0 rgba(61, 70, 79, 0.125);
}

.modal-dialog-overflow .modal-body-scrolled {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.popover-body p {
  margin-bottom: .5rem;
}

.text-blue {
  color: #0179A8 !important;
}

a.text-blue:hover, a.text-blue:focus {
  color: #015475 !important;
}

.text-indigo {
  color: #1b75bc !important;
}

a.text-indigo:hover, a.text-indigo:focus {
  color: #285489 !important;
}

.text-purple {
  color: #5F4B8B !important;
}

a.text-purple:hover, a.text-purple:focus {
  color: #48396a !important;
}

.text-pink {
  color: #B76BA3 !important;
}

a.text-pink:hover, a.text-pink:focus {
  color: #a14e8b !important;
}

.text-red {
  color: #EA6759 !important;
}

a.text-red:hover, a.text-red:focus {
  color: #e43e2c !important;
}

.text-orange {
  color: #EC935E !important;
}

a.text-orange:hover, a.text-orange:focus {
  color: #e77430 !important;
}

.text-yellow {
  color: #F7C46C !important;
}

a.text-yellow:hover, a.text-yellow:focus {
  color: #f4b13c !important;
}

.text-green {
  color: #A7C796 !important;
}

a.text-green:hover, a.text-green:focus {
  color: #8bb575 !important;
}

.text-teal {
  color: #00A28A !important;
}

a.text-teal:hover, a.text-teal:focus {
  color: #006f5f !important;
}

.text-cyan {
  color: #3686A0 !important;
}

a.text-cyan:hover, a.text-cyan:focus {
  color: #29667a !important;
}

.text-white {
  color: #ffffff !important;
}

a.text-white:hover, a.text-white:focus {
  color: #e6e6e6 !important;
}

.text-gray {
  color: #686F76 !important;
}

a.text-gray:hover, a.text-gray:focus {
  color: #50565b !important;
}

.text-gray-dark {
  color: #3D464F !important;
}

a.text-gray-dark:hover, a.text-gray-dark:focus {
  color: #272d32 !important;
}

.bg-blue {
  background-color: #0179A8 !important;
}

a.bg-blue:hover, a.bg-blue:focus,
button.bg-blue:hover,
button.bg-blue:focus {
  background-color: #015475 !important;
}

.bg-indigo {
  background-color: #1b75bc !important;
}

a.bg-indigo:hover, a.bg-indigo:focus,
button.bg-indigo:hover,
button.bg-indigo:focus {
  background-color: #285489 !important;
}

.bg-purple {
  background-color: #5F4B8B !important;
}

a.bg-purple:hover, a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
  background-color: #48396a !important;
}

.bg-pink {
  background-color: #B76BA3 !important;
}

a.bg-pink:hover, a.bg-pink:focus,
button.bg-pink:hover,
button.bg-pink:focus {
  background-color: #a14e8b !important;
}

.bg-red {
  background-color: #EA6759 !important;
}

a.bg-red:hover, a.bg-red:focus,
button.bg-red:hover,
button.bg-red:focus {
  background-color: #e43e2c !important;
}

.bg-orange {
  background-color: #EC935E !important;
}

a.bg-orange:hover, a.bg-orange:focus,
button.bg-orange:hover,
button.bg-orange:focus {
  background-color: #e77430 !important;
}

.bg-yellow {
  background-color: #F7C46C !important;
}

a.bg-yellow:hover, a.bg-yellow:focus,
button.bg-yellow:hover,
button.bg-yellow:focus {
  background-color: #f4b13c !important;
}

.bg-green {
  background-color: #A7C796 !important;
}

a.bg-green:hover, a.bg-green:focus,
button.bg-green:hover,
button.bg-green:focus {
  background-color: #8bb575 !important;
}

.bg-teal {
  background-color: #00A28A !important;
}

a.bg-teal:hover, a.bg-teal:focus,
button.bg-teal:hover,
button.bg-teal:focus {
  background-color: #006f5f !important;
}

.bg-cyan {
  background-color: #3686A0 !important;
}

a.bg-cyan:hover, a.bg-cyan:focus,
button.bg-cyan:hover,
button.bg-cyan:focus {
  background-color: #29667a !important;
}

.bg-white {
  background-color: #ffffff !important;
}

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important;
}

.bg-gray {
  background-color: #686F76 !important;
}

a.bg-gray:hover, a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: #50565b !important;
}

.bg-gray-dark {
  background-color: #3D464F !important;
}

a.bg-gray-dark:hover, a.bg-gray-dark:focus,
button.bg-gray-dark:hover,
button.bg-gray-dark:focus {
  background-color: #272d32 !important;
}

.bg-black {
  background-color: #131D28 !important;
}

a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: #030405 !important;
}

.bg-muted {
  background-color: #E9EAEB !important;
}

a.bg-muted:hover, a.bg-muted:focus,
button.bg-muted:hover,
button.bg-muted:focus {
  background-color: #ced1d3 !important;
}

.bg-facebook {
  background-color: #365397 !important;
}

a.bg-facebook:hover, a.bg-facebook:focus,
button.bg-facebook:hover,
button.bg-facebook:focus {
  background-color: #293e71 !important;
}

.bg-facebook {
  color: #ffffff !important;
}

.text-facebook {
  color: #365397 !important;
}

a.text-facebook:hover, a.text-facebook:focus {
  color: #293e71 !important;
}

.bg-twitter {
  background-color: #00a9f1 !important;
}

a.bg-twitter:hover, a.bg-twitter:focus,
button.bg-twitter:hover,
button.bg-twitter:focus {
  background-color: #0085be !important;
}

.bg-twitter {
  color: #ffffff !important;
}

.text-twitter {
  color: #00a9f1 !important;
}

a.text-twitter:hover, a.text-twitter:focus {
  color: #0085be !important;
}

.bg-linkedin {
  background-color: #006db3 !important;
}

a.bg-linkedin:hover, a.bg-linkedin:focus,
button.bg-linkedin:hover,
button.bg-linkedin:focus {
  background-color: #004e80 !important;
}

.bg-linkedin {
  color: #ffffff !important;
}

.text-linkedin {
  color: #006db3 !important;
}

a.text-linkedin:hover, a.text-linkedin:focus {
  color: #004e80 !important;
}

.bg-apple {
  background-color: #737373 !important;
}

a.bg-apple:hover, a.bg-apple:focus,
button.bg-apple:hover,
button.bg-apple:focus {
  background-color: #5a5a5a !important;
}

.bg-apple {
  color: #ffffff !important;
}

.text-apple {
  color: #737373 !important;
}

a.text-apple:hover, a.text-apple:focus {
  color: #5a5a5a !important;
}

.bg-google {
  background-color: #4285f4 !important;
}

a.bg-google:hover, a.bg-google:focus,
button.bg-google:hover,
button.bg-google:focus {
  background-color: #1266f1 !important;
}

.bg-google {
  color: #ffffff !important;
}

.text-google {
  color: #4285f4 !important;
}

a.text-google:hover, a.text-google:focus {
  color: #1266f1 !important;
}

.bg-google_plus {
  background-color: #e0452c !important;
}

a.bg-google_plus:hover, a.bg-google_plus:focus,
button.bg-google_plus:hover,
button.bg-google_plus:focus {
  background-color: #bd321c !important;
}

.bg-google_plus {
  color: #ffffff !important;
}

.text-google_plus {
  color: #e0452c !important;
}

a.text-google_plus:hover, a.text-google_plus:focus {
  color: #bd321c !important;
}

.bg-youtube {
  background-color: #ff3333 !important;
}

a.bg-youtube:hover, a.bg-youtube:focus,
button.bg-youtube:hover,
button.bg-youtube:focus {
  background-color: red !important;
}

.bg-youtube {
  color: #ffffff !important;
}

.text-youtube {
  color: #ff3333 !important;
}

a.text-youtube:hover, a.text-youtube:focus {
  color: red !important;
}

.bg-vimeo {
  background-color: #48b6ed !important;
}

a.bg-vimeo:hover, a.bg-vimeo:focus,
button.bg-vimeo:hover,
button.bg-vimeo:focus {
  background-color: #1aa3e8 !important;
}

.bg-vimeo {
  color: #1b75bc !important;
}

.text-vimeo {
  color: #48b6ed !important;
}

a.text-vimeo:hover, a.text-vimeo:focus {
  color: #1aa3e8 !important;
}

.bg-pinterest {
  background-color: #ce1a19 !important;
}

a.bg-pinterest:hover, a.bg-pinterest:focus,
button.bg-pinterest:hover,
button.bg-pinterest:focus {
  background-color: #a11413 !important;
}

.bg-pinterest {
  color: #ffffff !important;
}

.text-pinterest {
  color: #ce1a19 !important;
}

a.text-pinterest:hover, a.text-pinterest:focus {
  color: #a11413 !important;
}

.bg-yelp {
  background-color: #c30f00 !important;
}

a.bg-yelp:hover, a.bg-yelp:focus,
button.bg-yelp:hover,
button.bg-yelp:focus {
  background-color: #900b00 !important;
}

.bg-yelp {
  color: #ffffff !important;
}

.text-yelp {
  color: #c30f00 !important;
}

a.text-yelp:hover, a.text-yelp:focus {
  color: #900b00 !important;
}

.bg-dribbble {
  background-color: #ed4584 !important;
}

a.bg-dribbble:hover, a.bg-dribbble:focus,
button.bg-dribbble:hover,
button.bg-dribbble:focus {
  background-color: #e91765 !important;
}

.bg-dribbble {
  color: #ffffff !important;
}

.text-dribbble {
  color: #ed4584 !important;
}

a.text-dribbble:hover, a.text-dribbble:focus {
  color: #e91765 !important;
}

.bg-amazon {
  background-color: #ff9700 !important;
}

a.bg-amazon:hover, a.bg-amazon:focus,
button.bg-amazon:hover,
button.bg-amazon:focus {
  background-color: #cc7900 !important;
}

.bg-amazon {
  color: #1b75bc !important;
}

.text-amazon {
  color: #ff9700 !important;
}

a.text-amazon:hover, a.text-amazon:focus {
  color: #cc7900 !important;
}

.bg-ebay {
  background-color: #083790 !important;
}

a.bg-ebay:hover, a.bg-ebay:focus,
button.bg-ebay:hover,
button.bg-ebay:focus {
  background-color: #052560 !important;
}

.bg-ebay {
  color: #ffffff !important;
}

.text-ebay {
  color: #083790 !important;
}

a.text-ebay:hover, a.text-ebay:focus {
  color: #052560 !important;
}

.bg-skype {
  background-color: #00acf4 !important;
}

a.bg-skype:hover, a.bg-skype:focus,
button.bg-skype:hover,
button.bg-skype:focus {
  background-color: #0088c1 !important;
}

.bg-skype {
  color: #ffffff !important;
}

.text-skype {
  color: #00acf4 !important;
}

a.text-skype:hover, a.text-skype:focus {
  color: #0088c1 !important;
}

.bg-instagram {
  background-color: #396d9a !important;
}

a.bg-instagram:hover, a.bg-instagram:focus,
button.bg-instagram:hover,
button.bg-instagram:focus {
  background-color: #2b5375 !important;
}

.bg-instagram {
  color: #ffffff !important;
}

.text-instagram {
  color: #396d9a !important;
}

a.text-instagram:hover, a.text-instagram:focus {
  color: #2b5375 !important;
}

.bg-dropbox {
  background-color: #0d84de !important;
}

a.bg-dropbox:hover, a.bg-dropbox:focus,
button.bg-dropbox:hover,
button.bg-dropbox:focus {
  background-color: #0a67ae !important;
}

.bg-dropbox {
  color: #ffffff !important;
}

.text-dropbox {
  color: #0d84de !important;
}

a.text-dropbox:hover, a.text-dropbox:focus {
  color: #0a67ae !important;
}

.bg-flickr {
  background-color: #ea0066 !important;
}

a.bg-flickr:hover, a.bg-flickr:focus,
button.bg-flickr:hover,
button.bg-flickr:focus {
  background-color: #b70050 !important;
}

.bg-flickr {
  color: #ffffff !important;
}

.text-flickr {
  color: #ea0066 !important;
}

a.text-flickr:hover, a.text-flickr:focus {
  color: #b70050 !important;
}

.bg-github {
  background-color: #2f2f2f !important;
}

a.bg-github:hover, a.bg-github:focus,
button.bg-github:hover,
button.bg-github:focus {
  background-color: #161616 !important;
}

.bg-github {
  color: #ffffff !important;
}

.text-github {
  color: #2f2f2f !important;
}

a.text-github:hover, a.text-github:focus {
  color: #161616 !important;
}

.bg-basecamp {
  background-color: #6bbd6d !important;
}

a.bg-basecamp:hover, a.bg-basecamp:focus,
button.bg-basecamp:hover,
button.bg-basecamp:focus {
  background-color: #4ca94e !important;
}

.bg-basecamp {
  color: #1b75bc !important;
}

.text-basecamp {
  color: #6bbd6d !important;
}

a.text-basecamp:hover, a.text-basecamp:focus {
  color: #4ca94e !important;
}

.bg-tumblr {
  background-color: #304c68 !important;
}

a.bg-tumblr:hover, a.bg-tumblr:focus,
button.bg-tumblr:hover,
button.bg-tumblr:focus {
  background-color: #203345 !important;
}

.bg-tumblr {
  color: #ffffff !important;
}

.text-tumblr {
  color: #304c68 !important;
}

a.text-tumblr:hover, a.text-tumblr:focus {
  color: #203345 !important;
}

.bg-foursquare {
  background-color: #207dc5 !important;
}

a.bg-foursquare:hover, a.bg-foursquare:focus,
button.bg-foursquare:hover,
button.bg-foursquare:focus {
  background-color: #196199 !important;
}

.bg-foursquare {
  color: #ffffff !important;
}

.text-foursquare {
  color: #207dc5 !important;
}

a.text-foursquare:hover, a.text-foursquare:focus {
  color: #196199 !important;
}

.bg-box {
  background-color: #1277bc !important;
}

a.bg-box:hover, a.bg-box:focus,
button.bg-box:hover,
button.bg-box:focus {
  background-color: #0e5a8d !important;
}

.bg-box {
  color: #ffffff !important;
}

.text-box {
  color: #1277bc !important;
}

a.text-box:hover, a.text-box:focus {
  color: #0e5a8d !important;
}

.grayscale {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}

.notransition {
  -webkit-transition: none !important;
  transition: none !important;
}

@-webkit-keyframes pulse {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes pulse {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.pulse {
  -webkit-animation: pulse 2.5s ease infinite;
          animation: pulse 2.5s ease infinite;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 4px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.log-divider {
  position: relative;
  margin: 1.25rem 0;
  height: 2em;
  text-align: center;
}

.log-divider::before {
  content: '';
  position: relative;
  display: block;
  top: 1em;
  border-top: 1px solid rgba(61, 70, 79, 0.125);
  z-index: 1;
}

.log-divider > span {
  display: inline-block;
  position: relative;
  z-index: 2;
  border-radius: 50px;
  background: #ffffff;
  padding: 0 1em;
  color: #686F76;
  line-height: 2em;
}

.has-dragable .drag-indicator {
  opacity: 0;
}

.has-dragable:hover .drag-indicator {
  opacity: 1;
}

.drag-handle {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: -webkit-grab;
  cursor: grab;
}

.drag-indicator {
  display: inline-block;
  margin: 0 .5em;
  height: 8px;
  width: 6px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAAGUlEQVR42mNgAIJz507/Z4AxVqxYAuEgAwD26QnApt++GwAAAABJRU5ErkJggg==);
  -webkit-transform: translate3d(-0.5em, 0, 0);
          transform: translate3d(-0.5em, 0, 0);
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: -webkit-grab;
  cursor: grab;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.drag-indicator-lg {
  height: 14px;
  width: 8px;
}

.drag-indicator-tall {
  height: 20px;
  width: 8px;
}

.typing {
  margin: 0;
  position: relative;
  display: inline-block;
  line-height: inherit;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.typing .dot {
  margin-bottom: .125em;
  position: relative;
  display: inline-block;
  border-radius: 50%;
  width: .375rem;
  height: .375rem;
  background-color: #555a62;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-animation: typing 1.2s infinite ease;
          animation: typing 1.2s infinite ease;
}

.typing .dot:nth-child(2) {
  -webkit-animation-delay: 150ms;
          animation-delay: 150ms;
}

.typing .dot:nth-child(3) {
  -webkit-animation-delay: 300ms;
          animation-delay: 300ms;
}

@-webkit-keyframes typing {
  50%, 100%, 0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  25% {
    -webkit-transform: translateY(-150%);
            transform: translateY(-150%);
  }
}

@keyframes typing {
  50%, 100%, 0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  25% {
    -webkit-transform: translateY(-150%);
            transform: translateY(-150%);
  }
}

.circle {
  border-radius: 9999px !important;
}

/** The root StackedMenu */
.stacked-menu {
  position: relative;
  padding: .25rem 0;
  background-color: #F5F5F5;
  /* global state */
  /* nav child */
  /* nav level 1 */
  /* disabled state */
}

.stacked-menu ul.menu {
  display: block;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.stacked-menu ul.menu + .menu {
  margin-top: 1em;
}

.stacked-menu .menu-header {
  margin: .5em 0 0;
  padding: 0.5rem 1rem;
  color: #686F76;
  font-size: 0.8125rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.stacked-menu .menu-header > .badge,
.stacked-menu .menu-header > .label {
  position: absolute;
  right: 0.75rem;
  z-index: 2;
}

.stacked-menu .menu-subhead {
  display: none;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  color: #1b75bc;
  line-height: 1.75em;
  overflow: hidden;
}

.stacked-menu .menu-link {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  color: #686F76;
  text-decoration: none;
  line-height: 1.75em;
  overflow: hidden;
}

.stacked-menu .menu-link > .menu-icon {
  width: 1.25em;
  margin-right: .5em;
  text-align: center;
  font-size: 1.25rem;
  color: #555a62;
}

.stacked-menu .menu-link > .badge,
.stacked-menu .menu-link > .label {
  position: absolute;
  right: 0.75rem;
  margin-top: .25em;
  z-index: 2;
}

.stacked-menu .menu-link:focus {
  color: #1b75bc;
  background-color: transparent;
  text-decoration: none;
  overflow: visible;
}

.stacked-menu .menu-link:focus .menu-icon {
  color: #686F76;
}

.stacked-menu .menu-link:hover {
  color: #1b75bc;
  background-color: transparent;
  text-decoration: none;
  overflow: visible;
}

.stacked-menu .menu-link:hover .menu-icon {
  color: #686F76;
}

.stacked-menu .menu-item + .menu-item {
  margin-top: 0;
}

.stacked-menu .menu-item.has-open:not(.has-active) > .menu-link {
  background-color: transparent;
  color: #1b75bc;
}

.stacked-menu .menu-item.has-open:not(.has-active) > .menu-link .menu-icon {
  color: #686F76;
}

.stacked-menu .menu-item.has-active > .menu-link {
  background-color: transparent;
  color: #1b75bc;
}

.stacked-menu .menu-item.has-active > .menu-link .menu-icon {
  color: #1b75bc;
}

.stacked-menu .has-child {
  position: relative;
}

.stacked-menu .has-child > .menu {
  height: 0;
  visibility: hidden;
  opacity: 0;
  background-color: #F5F5F5;
}

.stacked-menu .has-child.has-active:hover > .menu,
.stacked-menu .has-child.has-open > .menu {
  overflow: visible;
}

.stacked-menu .has-child.has-active > .menu {
  height: auto;
  visibility: visible;
  opacity: 1;
}

.stacked-menu > .menu > .menu-item {
  /* level 1 */
}

.stacked-menu > .menu > .menu-item > .menu-link {
  color: #555a62;
}

.stacked-menu > .menu > .menu-item > .menu-link:focus {
  color: #131D28;
  background-color: #F5F5F5;
}

.stacked-menu > .menu > .menu-item > .menu-link:hover {
  color: #131D28;
  background-color: #F5F5F5;
}

.stacked-menu > .menu > .menu-item.has-open > .menu-link {
  color: #131D28;
  background-color: #F5F5F5;
}

.stacked-menu > .menu > .menu-item.has-active > .menu-link {
  background-color: #F5F5F5;
  color: #1b75bc;
}

.stacked-menu > .menu > .menu-item > .menu {
  /* level 2 */
}

.stacked-menu > .menu > .menu-item > .menu .menu-link {
  padding-left: 3rem;
}

.stacked-menu > .menu > .menu-item > .menu .menu {
  /* level 3 */
}

.stacked-menu > .menu > .menu-item > .menu .menu .menu-link {
  padding-left: 4rem;
}

.stacked-menu > .menu > .menu-item > .menu .menu .menu .menu-link {
  padding-left: 5rem;
}

.stacked-menu .menu-item.disabled > .menu-link,
.stacked-menu .menu-item > .menu-link.disabled {
  color: #686F76;
}

.stacked-menu .menu-item.disabled > .menu-link .menu-icon,
.stacked-menu .menu-item > .menu-link.disabled .menu-icon {
  color: #686F76;
}

.stacked-menu .menu-item.disabled > .menu-link:focus,
.stacked-menu .menu-item.disabled > .menu-link:focus .menu-icon,
.stacked-menu .menu-item > .menu-link.disabled:focus,
.stacked-menu .menu-item > .menu-link.disabled:focus .menu-icon {
  background-color: transparent;
  color: #686F76;
}

.stacked-menu .menu-item.disabled > .menu-link:hover,
.stacked-menu .menu-item.disabled > .menu-link:hover .menu-icon,
.stacked-menu .menu-item > .menu-link.disabled:hover,
.stacked-menu .menu-item > .menu-link.disabled:hover .menu-icon {
  background-color: transparent;
  color: #686F76;
}

/* Compact */
.stacked-menu-has-compact .has-child.has-active > .menu {
  visibility: hidden;
  height: 0;
  opacity: 0;
}

.stacked-menu-has-compact .has-child.has-active .menu-subhead {
  background-color: #F5F5F5;
}

.stacked-menu-has-compact .has-child .menu-subhead {
  display: block;
}

.stacked-menu-has-compact > .menu > .menu-item > .menu-link {
  text-align: center;
  padding-right: 0;
  padding-left: 0;
  max-height: 40px;
}

.stacked-menu-has-compact > .menu > .menu-item > .menu-link > .badge,
.stacked-menu-has-compact > .menu > .menu-item > .menu-link > .label {
  top: 0;
  right: 0.25rem;
}

.stacked-menu-has-compact > .menu > .menu-item > .menu-link .menu-icon {
  margin: 0;
  font-size: 1.25em;
  line-height: 1.5em;
}

.stacked-menu-has-compact > .menu > .menu-item.has-child > .menu-link .menu-text {
  display: none;
}

.stacked-menu-has-compact > .menu > .menu-header {
  margin: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.stacked-menu-has-compact > .menu > .menu-item:not(.has-child) > .menu-link .menu-text {
  position: absolute;
  display: block;
  top: 0;
  left: 100%;
  padding: 0.5rem 0.75rem 0.5rem 0;
  border-radius: 0 0 0 0;
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  background-color: #F5F5F5;
  line-height: 1.75em;
  white-space: nowrap;
  -webkit-transition: opacity 150ms linear;
  transition: opacity 150ms linear;
}

.stacked-menu-has-compact > .menu > .menu-item:not(.has-child) > .menu-link:hover .menu-text {
  width: auto;
  height: auto;
  visibility: visible;
  opacity: 1;
}

.stacked-menu-has-compact > .menu > .menu-item.has-active > .menu-link .menu-text {
  background-color: #F5F5F5;
}

.stacked-menu-has-compact > .menu > .menu-item.disabled > .menu-link .menu-text,
.stacked-menu-has-compact > .menu > .menu-item > .menu-link.disabled .menu-text {
  background-color: #F5F5F5;
}

.stacked-menu-has-compact > .menu > .menu-item.has-child > .menu-link:before {
  content: none;
}

.stacked-menu-has-compact > .menu > .menu-item.has-child > .menu-link .menu-text {
  padding: 0.5rem 1rem;
  text-align: left;
  width: 200px;
}

/* Behavior */
.stacked-menu-has-collapsible {
  /** caret angle, Only show on collapsible mode */
}

.stacked-menu-has-collapsible .has-child > .menu-link::after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '\f104';
  position: absolute;
  right: 0.75rem;
  top: 50%;
  -webkit-transform: translateY(-0.5em);
          transform: translateY(-0.5em);
  height: 1em;
  width: 1em;
  line-height: 1em;
  text-align: center;
}

.stacked-menu-has-collapsible .has-child.has-active > .menu-link::after,
.stacked-menu-has-collapsible .has-child.has-open > .menu-link::after {
  content: '\f107';
}

.stacked-menu-has-collapsible .has-child > .menu {
  visibility: visible;
  opacity: 1;
  overflow: hidden;
}

.stacked-menu-has-collapsible .has-child.has-open:not(.has-active) > .menu {
  height: auto;
}

/** collapsible on compact mode */
.stacked-menu-has-collapsible.stacked-menu-has-compact {
  /** hide caret on level 1 */
  /** child level 2 up */
}

.stacked-menu-has-collapsible.stacked-menu-has-compact > .menu > .has-child > .menu {
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
  -webkit-transition: opacity 150ms linear, -webkit-transform 150ms linear;
  transition: opacity 150ms linear, -webkit-transform 150ms linear;
  transition: opacity 150ms linear, transform 150ms linear;
  transition: opacity 150ms linear, transform 150ms linear, -webkit-transform 150ms linear;
}

.stacked-menu-has-collapsible.stacked-menu-has-compact > .menu > .has-child.has-open > .menu {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.stacked-menu-has-collapsible.stacked-menu-has-compact > .menu > .has-child > .menu-link:after {
  display: none;
}

.stacked-menu-has-collapsible.stacked-menu-has-compact .has-child.has-open > .menu {
  position: absolute;
  width: 200px;
  top: 0;
  left: 100%;
  height: auto;
  visibility: visible;
  opacity: 1;
  border-radius: 0 0 0 0;
}

.stacked-menu-has-collapsible.stacked-menu-has-compact .has-child > .menu .menu-link {
  padding-left: 1rem;
}

.stacked-menu-has-collapsible.stacked-menu-has-compact .menu .menu .has-child.has-open > .menu {
  position: static;
  width: auto;
  top: auto;
  left: auto;
}

.stacked-menu-has-collapsible.stacked-menu-has-compact .menu .menu .has-child > .menu .menu-link {
  padding-left: 2rem;
}

.stacked-menu-has-collapsible.stacked-menu-has-compact .menu .menu .has-child > .menu .menu .menu-link {
  padding-left: 3rem;
}

/* Give a transition */
.stacked-menu-has-hoverable {
  /** except level 1 .has-active */
}

.stacked-menu-has-hoverable .has-child > .menu {
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-transition: opacity 150ms linear, -webkit-transform 150ms linear;
  transition: opacity 150ms linear, -webkit-transform 150ms linear;
  transition: opacity 150ms linear, transform 150ms linear;
  transition: opacity 150ms linear, transform 150ms linear, -webkit-transform 150ms linear;
}

.stacked-menu-has-hoverable .has-child:not(.has-active) > .menu {
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
}

.stacked-menu-has-hoverable.stacked-menu-has-compact {
  /** level 1 */
  /** overwrite for all */
}

.stacked-menu-has-hoverable.stacked-menu-has-compact > .menu > .has-child > .menu {
  -webkit-transition: opacity 150ms linear, -webkit-transform 150ms linear;
  transition: opacity 150ms linear, -webkit-transform 150ms linear;
  transition: opacity 150ms linear, transform 150ms linear;
  transition: opacity 150ms linear, transform 150ms linear, -webkit-transform 150ms linear;
}

.stacked-menu-has-hoverable.stacked-menu-has-compact .has-child > .menu {
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
}

/* Behavior */
.stacked-menu-has-hoverable,
.stacked-menu-has-compact {
  /** except level 1 .has-active */
}

.stacked-menu-has-hoverable .has-child.has-open:not(.has-active),
.stacked-menu-has-compact .has-child.has-open:not(.has-active) {
  /** caret */
}

.stacked-menu-has-hoverable .has-child.has-open:not(.has-active) > .menu,
.stacked-menu-has-compact .has-child.has-open:not(.has-active) > .menu {
  position: absolute;
  width: 200px;
  top: 0;
  left: 100%;
  height: auto;
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.stacked-menu-has-hoverable .has-child.has-open:not(.has-active) > .menu .menu-item > .menu-link,
.stacked-menu-has-hoverable .has-child.has-open:not(.has-active) > .menu .menu-item > .menu .menu-link,
.stacked-menu-has-hoverable .has-child.has-open:not(.has-active) > .menu .menu-item > .menu .menu .menu-link,
.stacked-menu-has-compact .has-child.has-open:not(.has-active) > .menu .menu-item > .menu-link,
.stacked-menu-has-compact .has-child.has-open:not(.has-active) > .menu .menu-item > .menu .menu-link,
.stacked-menu-has-compact .has-child.has-open:not(.has-active) > .menu .menu-item > .menu .menu .menu-link {
  padding-left: 1rem;
}

.stacked-menu-has-hoverable .has-child.has-open:not(.has-active) > .menu-link:before,
.stacked-menu-has-compact .has-child.has-open:not(.has-active) > .menu-link:before {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  /** the caret */
  border-right: 8px solid #F5F5F5;
  -webkit-transform: translateY(-8px);
          transform: translateY(-8px);
}

/** hoverable on compact mode */
.stacked-menu-has-hoverable.stacked-menu-has-compact {
  /** overwrite for all */
}

.stacked-menu-has-hoverable.stacked-menu-has-compact .has-child.has-open > .menu {
  position: absolute;
  width: 200px;
  top: 0;
  left: 100%;
  height: auto;
  visibility: visible;
  opacity: 1;
  border-radius: 0 0 0 0;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.stacked-menu-has-hoverable.stacked-menu-has-compact .has-child.has-open > .menu .menu-item > .menu-link,
.stacked-menu-has-hoverable.stacked-menu-has-compact .has-child.has-open > .menu .menu-item > .menu .menu-link,
.stacked-menu-has-hoverable.stacked-menu-has-compact .has-child.has-open > .menu .menu-item > .menu .menu .menu-link {
  padding-left: 1rem;
}

/** open menu to left direction */
/** Core */
.stacked-menu-has-left {
  /** compact */
}

.stacked-menu-has-left.stacked-menu-has-compact > .menu > .menu-item > .menu-link > .badge {
  right: auto;
  left: 0.25rem;
}

.stacked-menu-has-left.stacked-menu-has-compact > .menu > .menu-item:not(.has-child) > .menu-link .menu-text {
  right: 100%;
  left: auto;
  padding: 0.5rem 0 0.5rem 0.75rem;
  border-radius: 0 0 0 0;
}

/** Behavior */
.stacked-menu-has-left {
  /** both hoverable & collapsible on compact mode */
}

.stacked-menu-has-left.stacked-menu-has-hoverable .has-child > .menu {
  -webkit-transform-origin: 100% 0;
          transform-origin: 100% 0;
}

.stacked-menu-has-left.stacked-menu-has-collapsible.stacked-menu-has-compact > .menu > .has-child > .menu {
  -webkit-transform-origin: 100% 0;
          transform-origin: 100% 0;
}

.stacked-menu-has-left.stacked-menu-has-hoverable .has-child.has-open:not(.has-active) > .menu, .stacked-menu-has-left.stacked-menu-has-compact .has-child.has-open:not(.has-active) > .menu {
  left: auto;
  right: 100%;
}

.stacked-menu-has-left.stacked-menu-has-hoverable .has-child.has-open:not(.has-active) > .menu-link:before, .stacked-menu-has-left.stacked-menu-has-compact .has-child.has-open:not(.has-active) > .menu-link:before {
  right: auto;
  left: 0;
  border-right: 0 solid transparent;
  border-left: 8px solid #F5F5F5;
}

.stacked-menu-has-left.stacked-menu-has-hoverable.stacked-menu-has-compact .has-child.has-open > .menu, .stacked-menu-has-left.stacked-menu-has-collapsible.stacked-menu-has-compact .has-child.has-open > .menu {
  left: auto;
  right: 100%;
}

.stacked-menu {
  padding-top: 1rem;
  width: 100%;
  margin-bottom: 1rem;
}

.stacked-menu .menu-link,
.stacked-menu .menu-subhead {
  padding-right: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.stacked-menu .menu-link {
  font-size: 1rem;
  outline: 0;
}

@media (min-width: 768px) {
  .stacked-menu .menu-link {
    padding-top: .375rem;
    padding-bottom: .375rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

.stacked-menu .menu-link > .badge {
  margin-top: .325rem;
}

.stacked-menu .menu-link > .menu-icon.fa, .stacked-menu .menu-link > .menu-icon.fab, .stacked-menu .menu-link > .menu-icon.far, .stacked-menu .menu-link > .menu-icon.oi {
  top: 2px;
}

.stacked-menu .menu-header {
  margin-top: 1rem;
  letter-spacing: 0;
  line-height: 1.25rem;
}

.stacked-menu .menu-header > .badge {
  position: relative;
  top: 3px;
  right: -.25rem;
  float: right;
}

.stacked-menu .menu-item.has-active:not(.has-child) > .menu-link {
  font-weight: 600;
}

.stacked-menu .menu-item.has-active.has-child > .menu-link {
  color: #1b75bc;
}

.stacked-menu > .menu > .menu-item > .menu .menu-link {
  padding-left: 3.5rem;
}

.stacked-menu > .menu > .menu-item > .menu .menu .menu .menu-link {
  padding-left: 4.5rem;
}

.stacked-menu-has-collapsible .has-child > .menu-link::after {
  content: '';
  margin-top: -0.375rem;
  opacity: .4;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: -webkit-transform 200ms ease-in-out;
  transition: -webkit-transform 200ms ease-in-out;
  transition: transform 200ms ease-in-out;
  transition: transform 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
  background: url("data:image/svg+xml;charset=UTF-8,%3csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3e%3cpath fill='currentColor' d='M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z'%3e%3c/path%3e%3c/svg%3e") center no-repeat;
}

.stacked-menu-has-collapsible .has-child.has-active > .menu-link::after,
.stacked-menu-has-collapsible .has-child.has-open > .menu-link::after {
  content: '';
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.masonry-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

/*
 * Container style
 */
.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}

/*
 * Scrollbar rail styles
 */
.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  height: 15px;
  /* there must be 'bottom' or 'top' for ps__rail-x */
  bottom: 0px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  width: 15px;
  /* there must be 'right' or 'left' for ps__rail-y */
  right: 0;
  /* please don't change 'position' */
  position: absolute;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.6;
}

.ps__rail-x:hover,
.ps__rail-y:hover,
.ps__rail-x:focus,
.ps__rail-y:focus {
  background-color: #eee;
  opacity: 0.9;
}

/*
 * Scrollbar thumb styles
 */
.ps__thumb-x {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color .2s linear, height .2s ease-in-out;
  -webkit-transition: background-color .2s linear, height .2s ease-in-out;
  height: 6px;
  /* there must be 'bottom' for ps__thumb-x */
  bottom: 2px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color .2s linear, width .2s ease-in-out;
  -webkit-transition: background-color .2s linear, width .2s ease-in-out;
  width: 6px;
  /* there must be 'right' for ps__thumb-y */
  right: 2px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x {
  background-color: #999;
  height: 11px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y {
  background-color: #999;
  width: 11px;
}

/* MS supports */
@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}

.ps__rail-y {
  width: 8px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y {
  width: 6px;
}

.dataTable {
  max-width: none;
}

.dataTable td.dataTables_empty,
.dataTable th.dataTables_empty {
  text-align: center;
}

.dataTable.nowrap th,
.dataTable.nowrap td {
  white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_length label {
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_length select {
  width: 75px;
  display: inline-block;
}

div.dataTables_wrapper div.dataTables_filter {
  text-align: right;
}

div.dataTables_wrapper div.dataTables_filter label {
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
}

div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0.5em;
  display: inline-block;
  width: auto;
}

div.dataTables_wrapper div.dataTables_info {
  white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_paginate {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin: 2px 0;
  white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  margin-left: -100px;
  margin-top: -26px;
  text-align: center;
  padding: 1em 0;
}

table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting {
  padding-right: 30px;
}

table.dataTable thead > tr > td.sorting_asc, table.dataTable thead > tr > td.sorting_desc, table.dataTable thead > tr > td.sorting {
  padding-right: 30px;
}

table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
  outline: none;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative;
}

table.dataTable thead .sorting:before, table.dataTable thead .sorting:after {
  position: absolute;
  bottom: 0.9em;
  display: block;
  opacity: 0.3;
}

table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_asc:after {
  position: absolute;
  bottom: 0.9em;
  display: block;
  opacity: 0.3;
}

table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_desc:after {
  position: absolute;
  bottom: 0.9em;
  display: block;
  opacity: 0.3;
}

table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_asc_disabled:after {
  position: absolute;
  bottom: 0.9em;
  display: block;
  opacity: 0.3;
}

table.dataTable thead .sorting_desc_disabled:before, table.dataTable thead .sorting_desc_disabled:after {
  position: absolute;
  bottom: 0.9em;
  display: block;
  opacity: 0.3;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  right: 1em;
  content: "\2191";
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  right: 0.5em;
  content: "\2193";
}

table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:after {
  opacity: 1;
}

table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  opacity: 0;
}

div.dataTables_scrollHead table.dataTable {
  margin-bottom: 0 !important;
}

div.dataTables_scrollBody table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

div.dataTables_scrollBody table thead .sorting:after,
div.dataTables_scrollBody table thead .sorting_asc:after,
div.dataTables_scrollBody table thead .sorting_desc:after {
  display: none;
}

div.dataTables_scrollBody table tbody tr:first-child th,
div.dataTables_scrollBody table tbody tr:first-child td {
  border-top: none;
}

div.dataTables_scrollFoot table {
  margin-top: 0 !important;
  border-top: none;
}

@media screen and (max-width: 767px) {
  div.dataTables_wrapper div.dataTables_length, div.dataTables_wrapper div.dataTables_filter, div.dataTables_wrapper div.dataTables_paginate {
    text-align: center;
  }
}

table.dataTable.table-condensed > thead > tr > th {
  padding-right: 20px;
}

table.dataTable.table-condensed .sorting:after,
table.dataTable.table-condensed .sorting_asc:after,
table.dataTable.table-condensed .sorting_desc:after {
  top: 6px;
  right: 6px;
}

table.table-bordered.dataTable th, table.table-bordered.dataTable td {
  border-left-width: 0;
}

table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable td:last-child {
  border-right-width: 0;
}

table.table-bordered.dataTable tbody th, table.table-bordered.dataTable tbody td {
  border-bottom-width: 0;
}

div.dataTables_scrollHead table.table-bordered {
  border-bottom-width: 0;
}

div.table-responsive > div.dataTables_wrapper > div.row {
  margin: 0;
}

div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:first-child {
  padding-left: 0;
}

div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:last-child {
  padding-right: 0;
}

div.dt-button-info {
  margin-top: 0;
  top: 3.5rem;
  color: #ffffff;
  background-color: #3D464F;
  border: none;
  -webkit-box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 2px 7px 1px rgba(61, 70, 79, 0.16);
          box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 2px 7px 1px rgba(61, 70, 79, 0.16);
  border-radius: 0.25rem;
}

div.dt-button-info h2 {
  border-bottom: none;
  background-color: #3D464F;
}

.plyr input[type="range"]:active::-webkit-slider-thumb {
  background: #1b75bc;
}

.plyr input[type="range"]:active::-moz-range-thumb {
  background: #1b75bc;
}

.plyr input[type="range"]:active::-ms-thumb {
  background: #1b75bc;
}

.plyr__control--overlaid,
.plyr__control--overlaid:focus,
.plyr__control--overlaid:hover,
.plyr--video .plyr__controls .plyr__control.plyr__tab-focus,
.plyr--video .plyr__controls .plyr__control:hover,
.plyr--video .plyr__controls .plyr__control[aria-expanded=true],
.plyr--audio .plyr__control.plyr__tab-focus,
.plyr--audio .plyr__control:hover,
.plyr--audio .plyr__control[aria-expanded=true] {
  background: #1b75bc;
}

.plyr--full-ui input[type=range] {
  color: #1b75bc;
}

.plyr--audio .plyr__controls {
  border: 0;
}

.sortable-ghost {
  opacity: .5;
}

.dd {
  position: relative;
}

.dd-list {
  position: relative;
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  list-style: none;
}

.dd-list .dd-list {
  padding-left: 1.25rem;
}

.dd-list .dd-list .dd-handle {
  border-left: 1px solid rgba(19, 29, 40, 0.125);
}

.dd-item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.dd-item:first-child > .dd-nodrag {
  margin-top: 2rem;
}

.dd-item > button {
  position: absolute;
  left: 2rem;
  top: 1.125rem;
  padding: 0;
  padding-left: 20px;
  width: 20px;
  height: 20px;
  border: 0;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px;
  overflow: hidden;
}

.dd-item > button.dd-expand {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg aria-hidden='true' data-prefix='fas' data-icon='caret-right' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' class='svg-inline--fa fa-caret-right fa-w-10 fa-3x'%3e%3cpath fill='currentColor' d='M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z' class=''/%3e%3c/svg%3e");
}

.dd-item > button.dd-collapse {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg aria-hidden='true' data-prefix='fas' data-icon='caret-down' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' class='svg-inline--fa fa-caret-down fa-w-10 fa-3x'%3e%3cpath fill='currentColor' d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/%3e%3c/svg%3e");
}

.dd-item > button + .dd-handle {
  padding-left: 2.5rem;
}

.dd-item > button + .dd-handle > .drag-indicator {
  -webkit-transform: translate3d(-2rem, 0, 0);
          transform: translate3d(-2rem, 0, 0);
  margin-right: .25rem;
}

.dd-footer {
  display: block;
  background-color: #F5F5F5;
}

.dd-handle {
  margin-bottom: -1px;
  padding: 0.75rem 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #ffffff;
  border-top: 1px solid rgba(19, 29, 40, 0.125);
  border-bottom: 1px solid rgba(19, 29, 40, 0.125);
}

.dd-handle:focus {
  text-decoration: none;
}

.dd-handle:hover {
  text-decoration: none;
}

.dd-expand {
  display: none;
}

.dd-collapsed .dd-list,
.dd-collapsed .dd-collapse {
  display: none;
}

.dd-collapsed .dd-expand {
  display: block;
}

.dd-placeholder {
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 30px;
  background: #fef6ea;
  border: 1px dashed #f8ce84;
}

.dd-empty {
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 56px;
  background-color: #F5F5F5;
  border: 1px dashed rgba(61, 70, 79, 0.125);
}

.dd-dragel {
  position: absolute;
  pointer-events: none;
  z-index: 2000;
}

.dd-dragel > .dd-item .dd-handle {
  margin-top: 0;
}

.dd-nochildren .dd-placeholder {
  display: none;
}

.jstree .fa {
  font-size: 1.1em;
}

.jstree-anchor {
  color: inherit;
}

.jstree-default .jstree-anchor {
  margin-top: 2px;
}

.jstree-default .jstree-checkbox {
  margin-top: -2px;
}

.jstree-default .jstree-hovered {
  background-color: #ECF1F7;
}

.jstree-default .jstree-clicked {
  background-color: #cedcec;
}

.jstree-default .jstree-disabled {
  color: #686F76;
}

.jstree-default .jstree-wholerow-hovered {
  background-color: #ECF1F7;
  background-image: none;
}

.jstree-default .jstree-wholerow-clicked {
  background-color: #cedcec;
  background-image: none;
}

.jstree-open > .jstree-anchor > .fa-folder:before {
  content: "\f07c";
}

.vakata-context,
.vakata-context ul {
  float: left;
  -webkit-box-shadow: 0 6px 12px rgba(61, 70, 79, 0.175);
          box-shadow: 0 6px 12px rgba(61, 70, 79, 0.175);
  border: 1px solid rgba(61, 70, 79, 0.125);
  border-radius: 4px;
  background: #fff;
  list-style: none;
  text-align: left;
}

.jstree-default .jstree-clicked .jstree-checkbox {
  border-color: #1b75bc;
}

.jstree-default .jstree-clicked .jstree-checkbox::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 1rem;
  height: 1rem;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%230179A8' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M6.41 0l-.69.72-2.78 2.78-.81-.78-.72-.72-1.41 1.41.72.72 1.5 1.5.69.72.72-.72 3.5-3.5.72-.72-1.44-1.41z' transform='translate(0 1)' /%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: 35% 35%;
  background-size: 65% 65%;
}

.jstree-default .jstree-checkbox {
  position: relative;
  background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#F5F5F5));
  background: linear-gradient(to bottom, #ffffff, #F5F5F5);
  border: 1px solid #D4D5D7;
  -webkit-box-shadow: 0 1px 0 0 rgba(61, 70, 79, 0.05);
          box-shadow: 0 1px 0 0 rgba(61, 70, 79, 0.05);
  border-radius: 0.25rem;
}

.jstree-default .jstree-checkbox:empty {
  margin-top: 3px;
  width: 1rem;
  height: 1rem;
}

.jstree-default .jstree-checkbox.jstree-undetermined:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 1rem;
  height: 1rem;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%230179A8' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M0 0v2h8v-2h-8z' transform='translate(0 3)' /%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: 35% 35%;
  background-size: 65% 65%;
}

.treeview .list-group {
  background-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.treeview .list-group-item {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: transparent;
  cursor: pointer;
}

.treeview .list-group-item .badge {
  margin-left: auto;
  color: #1b75bc;
  background-color: #E9EAEB;
}

.treeview span.indent {
  margin-left: .875rem !important;
  margin-right: .875rem !important;
}

.treeview span.icon {
  width: 12px;
  margin-right: 5px;
}

.treeview .node-disabled {
  color: #686F76;
  cursor: not-allowed;
}

.twitter-typeahead {
  width: 100%;
}

.tt-menu,
.tt-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  width: 100%;
  padding: 0.5rem 0.75rem;
  margin: .5rem 0 0;
  font-size: 1rem;
  color: #1b75bc;
  text-align: left;
  list-style: none;
  background-color: rgba(255, 255, 255, 0.98);
  background-clip: padding-box;
  border: 0;
  -webkit-box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 2px 7px 1px rgba(61, 70, 79, 0.16);
          box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 2px 7px 1px rgba(61, 70, 79, 0.16);
  -webkit-animation-duration: 200ms;
          animation-duration: 200ms;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  border-radius: 0.25rem;
}

@media (min-width: 768px) {
  .tt-menu,
  .tt-dropdown-menu {
    font-size: 0.875rem;
  }
}

.tt-menu.tt-open,
.tt-dropdown-menu.tt-open {
  -webkit-animation-name: showDropdown;
          animation-name: showDropdown;
}

.tt-menu .empty-message,
.tt-dropdown-menu .empty-message {
  padding: .5rem 1rem;
  text-align: center;
  color: #686F76;
}

.tt-hint {
  color: #686F76;
  padding-left: 0.75rem !important;
  border-width: 1px !important;
}

.tt-suggestion {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  clear: both;
  font-weight: 400;
  color: #1b75bc;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: 0.25rem;
  cursor: default;
}

.tt-suggestion strong {
  color: #1b75bc;
}

.tt-suggestion.tt-cursor, .tt-suggestion:hover, .tt-suggestion:focus {
  text-decoration: none;
  color: #ffffff;
  background-color: #1b75bc;
}

.tt-suggestion.tt-cursor strong, .tt-suggestion:hover strong, .tt-suggestion:focus strong {
  color: inherit;
}

.tt-menu-header {
  display: block;
  padding: 0.5rem 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #686F76;
  white-space: nowrap;
}

.has-typeahead-scrollable .tt-menu {
  max-height: 220px;
  overflow-y: auto;
}

.select2-dropdown {
  z-index: 1000;
}

.select2-container .select2-selection--single {
  height: 2.25rem;
  background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#F5F5F5));
  background: linear-gradient(to bottom, #ffffff, #F5F5F5);
  -webkit-transition: border-color 200ms ease-in-out, -webkit-box-shadow 200ms ease-in-out;
  transition: border-color 200ms ease-in-out, -webkit-box-shadow 200ms ease-in-out;
  transition: box-shadow 200ms ease-in-out, border-color 200ms ease-in-out;
  transition: box-shadow 200ms ease-in-out, border-color 200ms ease-in-out, -webkit-box-shadow 200ms ease-in-out;
}

@media (min-width: 768px) {
  .select2-container .select2-selection--single {
    height: 2.188rem;
  }
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  padding-left: 0.75rem;
  padding-right: 2rem;
  line-height: 2.25rem;
  color: #1b75bc;
}

@media (min-width: 768px) {
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 2.188rem;
  }
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #555a62;
}

.select2-container--disabled .select2-selection--single .select2-selection__placeholder,
.select2-container--disabled .select2-selection--multiple .select2-selection__choice {
  color: #686F76;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: -1px;
  right: 0px;
  height: 2.25rem;
  width: 2.5rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

@media (min-width: 768px) {
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 2.188rem;
  }
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-top-color: #1b75bc;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-bottom-color: #1b75bc;
}

.select2-container .select2-selection--multiple {
  min-height: 2.25rem;
  -webkit-box-shadow: inset 0 1px 0 0 rgba(61, 70, 79, 0.05);
          box-shadow: inset 0 1px 0 0 rgba(61, 70, 79, 0.05);
}

@media (min-width: 768px) {
  .select2-container .select2-selection--multiple {
    min-height: 2.188rem;
  }
}

.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--multiple {
  border-color: #D4D5D7;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  display: block;
  padding: 2px 0.75rem;
}

.select2-container--focus .select2-selection--single,
.select2-container--default.select2-container--focus .select2-selection--multiple,
.select2-container--default.select2-container--open .select2-selection--multiple,
.select2-container--open .select2-selection--single {
  outline: 0;
  border-color: #1b75bc;
  -webkit-box-shadow: 0 0 0 1px #1b75bc;
          box-shadow: 0 0 0 1px #1b75bc;
}

.select2-container--default.select2-container--disabled .select2-selection--single,
.select2-container--default.select2-container--disabled .select2-selection--multiple {
  cursor: not-allowed;
  border-color: #D4D5D7;
  background-color: #F5F5F5;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-search__field,
.select2-container--default.select2-container--disabled .select2-selection--multiple .select2-search__field {
  cursor: not-allowed;
}

.select2-container--open .select2-dropdown--above {
  margin-top: 1px;
  -webkit-box-shadow: -1px -1px 0px 0px #1b75bc, 1px 0px 0px 0px #1b75bc;
          box-shadow: -1px -1px 0px 0px #1b75bc, 1px 0px 0px 0px #1b75bc;
}

.select2-container--open .select2-dropdown--below {
  margin-top: -1px;
  -webkit-box-shadow: -1px 1px 0px 0px #1b75bc, 1px 0px 0px 0px #1b75bc;
          box-shadow: -1px 1px 0px 0px #1b75bc, 1px 0px 0px 0px #1b75bc;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  color: #686F76;
  z-index: 2;
}

.select2-dropdown {
  border-color: #1b75bc;
  -webkit-box-shadow: 0 0 0 1px #1b75bc;
          box-shadow: 0 0 0 1px #1b75bc;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  border-color: #D4D5D7;
  -webkit-box-shadow: inset 0 1px 0 0 rgba(61, 70, 79, 0.05);
          box-shadow: inset 0 1px 0 0 rgba(61, 70, 79, 0.05);
}

.select2-container--default .select2-search--dropdown .select2-search__field:focus {
  outline: 0;
  border-color: #BEC1C4;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #E9EAEB;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #1b75bc;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] .text-muted {
  color: inherit !important;
}

.select2-container--default .select2-results__group,
.select2-results__option {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.select2-container .select2-search--inline {
  margin-top: 2px;
}

.select2-container .select2-search--inline .select2-search__field {
  margin-top: 3px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  margin: 3px 12px 3px -6px;
  background-color: #E9EAEB;
  border-color: #E9EAEB;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice + .select2-search {
  margin-left: -6px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  margin-right: auto;
  margin-left: .25rem;
  position: relative;
  top: 4px;
  width: 0.875rem;
  height: 0.875rem;
  font-size: 0.875rem;
  line-height: 1.3;
  float: right;
  color: #ffffff;
  background-color: #555a62;
  text-align: center;
  border-radius: 0.875rem;
}

@media (min-width: 768px) {
  .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    top: 3px;
  }
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus {
  color: #ffffff;
  background-color: #686F76;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #ffffff;
  background-color: #686F76;
}

.atwho-view,
.tribute-container {
  z-index: 1000;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #1b75bc;
  text-align: left;
  list-style: none;
  background-color: rgba(255, 255, 255, 0.98);
  background-clip: padding-box;
  border: 0;
  -webkit-box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 2px 7px 1px rgba(61, 70, 79, 0.16);
          box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 2px 7px 1px rgba(61, 70, 79, 0.16);
  -webkit-animation-duration: 200ms;
          animation-duration: 200ms;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  border-radius: 0.25rem;
}

@media (min-width: 768px) {
  .atwho-view,
  .tribute-container {
    font-size: 0.875rem;
  }
}

.atwho-view ul li,
.tribute-container li {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  clear: both;
  font-weight: 400;
  color: #1b75bc;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.tribute-container {
  margin-top: .5rem;
}

.tribute-container ul {
  background-color: inherit;
  margin-top: 0;
}

.tribute-container li span {
  color: #F7C46C;
}

.tribute-container li.highlight, .tribute-container li:hover, .tribute-container li:focus {
  text-decoration: none;
  color: #ffffff;
  background-color: #1b75bc;
}

.tribute-container li.highlight span, .tribute-container li:hover span, .tribute-container li:focus span {
  color: inherit;
}

.atwho-view ul li {
  border-bottom: none;
}

.atwho-view strong {
  color: #F7C46C;
}

.atwho-view .cur {
  color: #ffffff;
  background-color: #1b75bc;
}

.atwho-view .cur strong {
  color: inherit;
}

.flatpickr-calendar {
  border: 0;
  -webkit-box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 2px 7px 1px rgba(61, 70, 79, 0.16);
          box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 2px 7px 1px rgba(61, 70, 79, 0.16);
}

.flatpickr-calendar.inline {
  border: 1px solid rgba(61, 70, 79, 0.125);
  -webkit-box-shadow: none;
          box-shadow: none;
}

.flatpickr-calendar.open {
  z-index: 1000;
}

.dropdown-menu .flatpickr-calendar {
  width: auto !important;
  border-radius: 0;
  border-width: 1px 0 0;
}

.flatpickr-months {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  position: static;
  height: auto;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 10px;
  height: 10px;
}

.flatpickr-months .flatpickr-prev-month:focus svg,
.flatpickr-months .flatpickr-next-month:focus svg {
  fill: #1b75bc;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #1b75bc;
}

.flatpickr-month {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #1b75bc;
  fill: #1b75bc;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: auto;
}

.flatpickr-prev-month,
.flatpickr-next-month,
.flatpickr-current-month {
  position: relative;
  width: auto;
  height: auto;
}

.flatpickr-current-month {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0;
  left: auto;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
}

.flatpickr-current-month input.cur-year {
  font-weight: inherit;
}

.flatpickr-weekdays {
  height: auto;
}

span.flatpickr-weekday {
  padding: .5rem 0;
  font-size: inherit;
  font-weight: normal;
  color: inherit;
  text-decoration: underline;
  -webkit-text-decoration-style: dotted;
          text-decoration-style: dotted;
  border-width: 1px 1px 0 0;
  border-style: solid;
  border-color: rgba(72, 83, 93, 0.125);
}

span.flatpickr-weekday:last-child {
  border-right: 1px solid transparent;
}

.dayContainer {
  width: auto;
  max-width: none;
}

.flatpickr-days {
  width: auto;
}

.dropdown-menu .flatpickr-days {
  width: auto !important;
}

.flatpickr-day {
  max-width: initial;
  height: auto;
  line-height: 1;
  border-radius: 0;
  padding: .5rem 0;
  border-width: 1px 1px 0 0;
  border-color: rgba(72, 83, 93, 0.125);
}

.flatpickr-day:nth-child(7n+7) {
  border-right: none;
}

.rangeMode .flatpickr-day {
  margin-top: 0;
}

.flatpickr-day.inRange {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.flatpickr-day.today {
  color: #1b75bc;
  background-color: #C7D6E9;
  border-color: #ECF1F7;
}

.flatpickr-day.today:focus {
  color: #1b75bc;
  background-color: #C7D6E9;
  border-color: #ECF1F7;
}

.flatpickr-day.today:hover {
  color: #1b75bc;
  background-color: #C7D6E9;
  border-color: #ECF1F7;
}

.flatpickr-day.disabled,
.flatpickr-day.disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: #555a62;
  border-color: rgba(72, 83, 93, 0.125);
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus,
.numInputWrapper:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #DAE4F0;
  border-color: #ECF1F7;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  color: #ffffff;
  background: #1b75bc;
  border-color: #1b75bc;
}

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange,
.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0;
}

.flatpickr-day.selected.startRange + .endRange,
.flatpickr-day.startRange.startRange + .endRange,
.flatpickr-day.endRange.startRange + .endRange {
  -webkit-box-shadow: -10px 0 0 #1b75bc;
          box-shadow: -10px 0 0 #1b75bc;
}

.colorpicker {
  border: 0;
  -webkit-box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 2px 7px 1px rgba(61, 70, 79, 0.16);
          box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 2px 7px 1px rgba(61, 70, 79, 0.16);
}

.colorpicker.colorpicker-inline {
  border: 1px solid rgba(61, 70, 79, 0.125);
  -webkit-box-shadow: none;
          box-shadow: none;
}

.colorpicker-bar {
  line-height: 1rem;
}

.colorpicker-2x .colorpicker-saturation {
  width: 200px;
  height: 200px;
}

.colorpicker-2x .colorpicker-hue,
.colorpicker-2x .colorpicker-alpha {
  width: 30px;
  height: 200px;
}

.colorpicker-2x .colorpicker-preview,
.colorpicker-2x .colorpicker-preview div {
  height: 30px;
  font-size: 16px;
  line-height: 160%;
}

.colorpicker-2x .colorpicker-saturation .colorpicker-guide,
.colorpicker-2x .colorpicker-saturation .colorpicker-guide i {
  height: 10px;
  width: 10px;
  border-radius: 10px;
}

.modal-body > .colorpicker-element {
  margin: 1rem 0;
}

.fileinput-dropzone,
.fileinput-button {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.fileinput-dropzone input,
.fileinput-button input {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  direction: ltr;
  cursor: pointer;
  z-index: 2;
}

.fileinput-dropzone:focus .fileinput-button-label,
.fileinput-button:focus .fileinput-button-label {
  opacity: 1;
}

.fileinput-dropzone:hover .fileinput-button-label,
.fileinput-button:hover .fileinput-button-label {
  opacity: 1;
}

.fileinput-dropzone {
  display: block;
  padding: 2.5rem;
  text-align: center;
  color: #686F76;
  border-radius: 0.25rem;
  border: 2px dashed rgba(61, 70, 79, 0.125);
  -webkit-transition: all 200ms;
  transition: all 200ms;
}

.fileinput-dropzone.hover {
  color: #1b75bc;
  border-color: #1b75bc;
}

.fileinput-button-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 1rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(52, 108, 176, 0.86);
  color: #ffffff;
  font-size: 0.875rem;
  text-align: center;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
  -webkit-transition: opacity 200ms;
  transition: opacity 200ms;
}

.bootstrap-touchspin .input-group-btn-vertical > .btn {
  border: 0;
}

.nouislider-wrapper {
  margin-bottom: 1.25rem;
}

.noUi-handle {
  padding: 0;
  display: inline-block;
  background-color: #ffffff;
  color: #ffffff;
  border: 4px solid #1b75bc;
  vertical-align: middle;
  border-radius: 1.25rem;
  line-height: 1;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  white-space: normal;
  -webkit-transform-origin: center center;
          transform-origin: center center;
  -webkit-transition: -webkit-box-shadow 150ms;
  transition: -webkit-box-shadow 150ms;
  transition: box-shadow 150ms;
  transition: box-shadow 150ms, -webkit-box-shadow 150ms;
  outline: 0;
}

.noUi-handle:before, .noUi-handle:after {
  display: none;
}

.noUi-handle:hover, .noUi-handle:focus, .noUi-handle:active {
  -webkit-box-shadow: 0 0 0 4px rgba(52, 108, 176, 0.26);
          box-shadow: 0 0 0 4px rgba(52, 108, 176, 0.26);
}

.noUi-horizontal {
  height: 4px;
}

.noUi-horizontal .noUi-handle {
  width: 1.25rem;
  height: 1.25rem;
  left: -12px;
  top: -8px;
}

html:not([dir=rtl]) .noUi-horizontal .noUi-handle {
  right: -0.625rem;
}

.noUi-vertical {
  width: 4px;
}

.noUi-vertical .noUi-handle {
  width: 1.25rem;
  height: 1.25rem;
  left: -8px;
  top: -12px;
}

.noUi-target {
  background-color: #E9EAEB;
  border: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.noUi-connect {
  background-color: #1b75bc;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.noUi-handle,
.noUi-active {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.noUi-pips {
  color: #686F76;
  font-size: 0.875rem;
}

.noUi-pips-horizontal {
  padding: 6px 0;
  height: 55px;
}

.noUi-pips-vertical {
  padding: 0 6px;
  width: 55px;
}

.noUi-marker {
  background-color: #E9EAEB;
}

.noUi-marker-sub,
.noUi-marker-large {
  background-color: #E9EAEB;
}

.noUi-value-horizontal {
  margin-top: 4px;
}

.noUi-value-vertical {
  margin-left: 4px;
}

.noUi-tooltip {
  font-size: 0.875rem;
  color: #ffffff;
  border-color: transparent;
  background-color: #131D28;
  opacity: .9;
  border-radius: 0.25rem;
}

.noUi-horizontal .noUi-tooltip {
  bottom: 200%;
}

.noUi-vertical .noUi-tooltip {
  right: 200%;
}

.noUi-primary .noUi-connect {
  background-color: #1b75bc;
}

.noUi-primary .noUi-handle {
  border-color: #1b75bc;
}

.noUi-primary .noUi-handle:hover, .noUi-primary .noUi-handle:focus, .noUi-primary .noUi-handle:active {
  -webkit-box-shadow: 0 0 0 4px rgba(52, 108, 176, 0.26);
          box-shadow: 0 0 0 4px rgba(52, 108, 176, 0.26);
}

.noUi-success .noUi-connect {
  background-color: #00A28A;
}

.noUi-success .noUi-handle {
  border-color: #00A28A;
}

.noUi-success .noUi-handle:hover, .noUi-success .noUi-handle:focus, .noUi-success .noUi-handle:active {
  -webkit-box-shadow: 0 0 0 4px rgba(0, 162, 138, 0.26);
          box-shadow: 0 0 0 4px rgba(0, 162, 138, 0.26);
}

.noUi-info .noUi-connect {
  background-color: #0179A8;
}

.noUi-info .noUi-handle {
  border-color: #0179A8;
}

.noUi-info .noUi-handle:hover, .noUi-info .noUi-handle:focus, .noUi-info .noUi-handle:active {
  -webkit-box-shadow: 0 0 0 4px rgba(1, 121, 168, 0.26);
          box-shadow: 0 0 0 4px rgba(1, 121, 168, 0.26);
}

.noUi-warning .noUi-connect {
  background-color: #F7C46C;
}

.noUi-warning .noUi-handle {
  border-color: #F7C46C;
}

.noUi-warning .noUi-handle:hover, .noUi-warning .noUi-handle:focus, .noUi-warning .noUi-handle:active {
  -webkit-box-shadow: 0 0 0 4px rgba(247, 196, 108, 0.26);
          box-shadow: 0 0 0 4px rgba(247, 196, 108, 0.26);
}

.noUi-danger .noUi-connect {
  background-color: #B76BA3;
}

.noUi-danger .noUi-handle {
  border-color: #B76BA3;
}

.noUi-danger .noUi-handle:hover, .noUi-danger .noUi-handle:focus, .noUi-danger .noUi-handle:active {
  -webkit-box-shadow: 0 0 0 4px rgba(183, 107, 163, 0.26);
          box-shadow: 0 0 0 4px rgba(183, 107, 163, 0.26);
}

.noUi-dark .noUi-connect {
  background-color: #3D464F;
}

.noUi-dark .noUi-handle {
  border-color: #3D464F;
}

.noUi-dark .noUi-handle:hover, .noUi-dark .noUi-handle:focus, .noUi-dark .noUi-handle:active {
  -webkit-box-shadow: 0 0 0 4px rgba(61, 70, 79, 0.26);
          box-shadow: 0 0 0 4px rgba(61, 70, 79, 0.26);
}

.noUi-target[disabled] .noUi-connect {
  background-color: #E9EAEB;
}

.noUi-target[disabled] .noUi-handle {
  border-color: #E9EAEB;
  background-color: #F5F5F5;
}

.noUi-origin[disabled] .noUi-handle {
  border-color: #E9EAEB;
  background-color: #F5F5F5;
}

.ql-container {
  font-size: inherit;
  font-family: inherit;
}

.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  border-color: rgba(61, 70, 79, 0.125);
}

.card > .ql-toolbar.ql-snow {
  border-width: 0 0 1px 0;
}

.card > .ql-container.ql-snow {
  border-width: 0;
}

.ql-snow .ql-tooltip {
  z-index: 1070;
}

.note-editor.note-frame {
  border-color: rgba(61, 70, 79, 0.125);
  -webkit-box-shadow: none;
          box-shadow: none;
}

.card > .note-editor.note-frame {
  margin-bottom: 0;
  border: 0;
}

.note-editing-area,
.note-codable {
  min-height: 100px;
}

.note-editor.note-frame .note-editing-area .note-editable {
  color: #1b75bc;
}

.note-placeholder {
  color: #555a62;
}

.note-group-image-url {
  overflow: initial !important;
}

.note-popover .popover-content .dropdown-menu.note-check a i,
.card-header.note-toolbar .dropdown-menu.note-check a i {
  color: #1b75bc;
}

.CodeMirror-wrap,
.editor-toolbar {
  border-color: rgba(61, 70, 79, 0.125);
  color: #1b75bc;
  background-color: #fff;
}

.card > .CodeMirror-wrap, .card >
.editor-toolbar {
  border: 0;
}

.card > .editor-toolbar {
  border-bottom: 1px solid rgba(61, 70, 79, 0.125);
}

.CodeMirror-fullscreen,
.editor-toolbar.fullscreen,
.editor-preview-side {
  z-index: 1050;
}

.editor-preview-side {
  border-top: 0;
}

.cm-header,
.cm-strong {
  font-weight: 500;
}

.ace_editor {
  min-height: 200px;
  width: 100%;
  font-size: 90%;
  line-height: 1.25rem !important;
}

.card > .ace_editor {
  margin-bottom: 0;
  border-radius: 0;
}

.ace_statusbar {
  padding: .5em;
  font-size: 0.75rem;
  color: #686F76;
}

.ace_statusbar::after {
  display: block;
  clear: both;
  content: "";
}

.ace_statusbar-dark {
  background-color: #3D464F;
  color: #F5F5F5;
}

.ace_statusbar-dark .ace_status-indicator {
  border-color: #535A62;
}

.ace_status-indicator {
  margin-left: auto;
  padding: 0 .5em;
  -ms-flex-item-align: center;
      align-self: center;
  float: right;
  font-size: 0.75rem;
  line-height: 1;
  border-left: 1px solid rgba(61, 70, 79, 0.125);
}

#toast-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 1rem;
  right: 0;
  left: 0;
  font-size: 0.875rem;
}

#toast-container.toast-top-full-width > div:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

#toast-container.toast-bottom-full-width > div:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

#toast-container.toast-top-center, #toast-container.toast-top-right, #toast-container.toast-top-left {
  top: 4.5rem;
}

#toast-container.toast-top-full-width {
  top: 3.5rem;
}

#toast-container.toast-bottom-center, #toast-container.toast-bottom-right, #toast-container.toast-bottom-left {
  bottom: 1rem;
}

#toast-container.toast-bottom-full-width {
  bottom: 0;
}

#toast-container.toast-top-left, #toast-container.toast-bottom-left {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

#toast-container.toast-top-right, #toast-container.toast-bottom-right {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

#toast-container > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 1rem 3.25rem 1rem 1rem;
  margin: 0 !important;
  width: auto !important;
  min-width: 288px;
  max-width: 568px;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  -webkit-box-shadow: 0 2px 5px 0 rgba(61, 70, 79, 0.2);
          box-shadow: 0 2px 5px 0 rgba(61, 70, 79, 0.2);
}

#toast-container > div:hover {
  -webkit-box-shadow: 0 2px 5px 0 rgba(61, 70, 79, 0.2);
          box-shadow: 0 2px 5px 0 rgba(61, 70, 79, 0.2);
}

#toast-container > div + div {
  margin-top: .5rem !important;
}

#toast-container > .toast-success,
#toast-container > .toast-info,
#toast-container > .toast-error,
#toast-container > .toast-warning {
  background-image: none !important;
}

.toast-close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  margin-left: .5em;
  float: none;
  opacity: .4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}

.toast-close-button:focus {
  color: #fff;
  opacity: .65;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=65);
  filter: alpha(opacity=65);
}

.toast-close-button:hover {
  color: #fff;
  opacity: .65;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=65);
  filter: alpha(opacity=65);
}

.toast {
  background-color: #3D464F;
}

.toast-success {
  background-color: #00A28A;
}

.toast-error {
  background-color: #B76BA3;
}

.toast-info {
  background-color: #3D464F;
}

.toast-warning {
  background-color: #EC935E;
}

.toast-progress {
  opacity: 0.15;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=15);
  filter: alpha(opacity=15);
}

@media (max-width: 575.98px) {
  #toast-container {
    padding: 0;
  }
  #toast-container.toast-top-center, #toast-container.toast-top-right, #toast-container.toast-top-left, #toast-container.toast-bottom-center, #toast-container.toast-bottom-right, #toast-container.toast-bottom-left {
    left: 0;
    right: 0;
  }
  #toast-container.toast-top-center > div, #toast-container.toast-top-right > div, #toast-container.toast-top-left > div, #toast-container.toast-bottom-center > div, #toast-container.toast-bottom-right > div, #toast-container.toast-bottom-left > div {
    width: 100% !important;
    max-width: 100%;
  }
  #toast-container.toast-top-full-width > div, #toast-container.toast-bottom-full-width > div {
    width: 100% !important;
    max-width: 100%;
  }
  #toast-container.toast-top-full-width, #toast-container.toast-top-center, #toast-container.toast-top-right, #toast-container.toast-top-left {
    top: 3.5rem;
  }
  #toast-container.toast-bottom-center, #toast-container.toast-bottom-right, #toast-container.toast-bottom-left {
    bottom: 0;
  }
  #toast-container > div {
    padding: 1rem 3.25rem 1rem 1rem;
  }
  #toast-container > div.rtl {
    padding: 1rem 1rem 1rem 3.25rem;
  }
  #toast-container > div {
    border-radius: 0;
    margin-bottom: 0;
  }
  #toast-container .toast-close-button {
    top: 1rem;
    right: 1rem;
  }
  #toast-container .rtl .toast-close-button {
    right: auto;
    left: 1rem;
  }
}

.toast-top-full-width > div,
.toast-top-center > div {
  -webkit-animation: toastTop 300ms;
          animation: toastTop 300ms;
}

.toast-top-right > div {
  -webkit-animation: toastRight 300ms;
          animation: toastRight 300ms;
}

.toast-top-left > div {
  -webkit-animation: toastLeft 300ms;
          animation: toastLeft 300ms;
}

@media (max-width: 575.98px) {
  .toast-top-right > div,
  .toast-top-left > div {
    -webkit-animation: toastTop 300ms;
            animation: toastTop 300ms;
  }
}

.toast-bottom-full-width > div,
.toast-bottom-center > div,
.toast-bottom-right > div,
.toast-bottom-left > div {
  -webkit-animation: toastBottom 300ms;
          animation: toastBottom 300ms;
}

@-webkit-keyframes toastBottom {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@keyframes toastBottom {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes toastTop {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@keyframes toastTop {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes toastRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@keyframes toastRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes toastLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@keyframes toastLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

.chartjs {
  height: 250px;
}

.chartjs-custom-tooltip {
  opacity: 1;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 3px;
  -webkit-transition: all .1s ease;
  transition: all .1s ease;
  pointer-events: none;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  z-index: 1100;
}

.chartjs-custom-tooltip .chartjs-tooltip-key {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

.flot {
  min-height: 200px;
}

.flot .legendColorBox {
  margin-bottom: 4px;
}

.flot .legendLabel {
  padding-left: 6px;
  padding-right: 8px;
}

.flot .legendLabel,
.flot .flot-tick-label {
  color: #686F76;
}

.flot-tooltip {
  position: absolute;
  display: none;
  padding: 3px 8px;
  font-size: 12px;
  color: #ffffff;
  background-color: #131D28;
  border: 1px solid #131D28;
  border-radius: 0.25rem;
  opacity: .9;
}

.easyPieChart {
  position: relative;
  text-align: center;
  font-size: 1.25rem;
}

.easyPieChart > span {
  position: absolute;
  display: inline-block;
  margin-top: -.125em;
  padding: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.chart-inline-group {
  position: relative;
  min-height: 131px;
}

.chart-inline-group .easyPieChart {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.sparkline {
  vertical-align: middle;
}

.jqstooltip {
  background-color: #131D28 !important;
  border-radius: 0.25rem !important;
  padding: 10px;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
}

.vmap {
  width: 100%;
  height: 100%;
}

.jqvmap-zoomin,
.jqvmap-zoomout {
  width: auto;
  height: auto;
  padding: 6px 4px;
  color: #ffffff;
  background: #3D464F;
  border-radius: 0;
}

.map-pin {
  width: 64px;
  height: 42px;
  position: absolute;
  top: -25px;
  left: -32px;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  text-align: center;
  background-position: top center;
  color: #686F76;
  font-size: 12px;
}

.map-pin span {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  left: 0;
}

canvas.particles-js-canvas-el {
  position: absolute;
  top: 0;
  left: 0;
}

#session-timeout-dialog .modal-header > .close {
  display: none;
}

*:focus {
  outline-color: #1b75bc;
}

*::-moz-selection {
  background: #F7C46C;
  color: #1b75bc;
  text-shadow: none;
}

*::selection {
  background: #F7C46C;
  color: #1b75bc;
  text-shadow: none;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  background-color: #F5F5F5;
}

@media (min-width: 768px) {
  body {
    font-size: 0.875rem;
  }
}

iframe {
  margin-bottom: 1rem;
}

.app {
  width: 100%;
  min-height: 100%;
}

@media (min-width: 768px) {
  .app.has-clipped-header .app-header {
    left: 15rem;
  }
}

.app.has-clipped-header .top-bar-brand {
  width: auto;
  background-color: transparent;
}

.app.has-clipped-header .app-aside {
  top: 0;
}

.app.has-fullwidth .app-main {
  padding-left: 0;
}

.app.has-fullwidth .top-bar-brand {
  background-color: transparent;
}

.app.has-fullwidth .app-aside {
  top: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
          transform: translate3d(-100%, 0, 0);
  z-index: 1050;
  border-right: 0;
}

.app.has-fullwidth .app-aside.has-open {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.app.has-fullwidth .app-backdrop.show {
  display: block;
  -webkit-animation: fadeInBackdrop 260ms ease forwards;
          animation: fadeInBackdrop 260ms ease forwards;
}

.app-main {
  position: relative;
  padding-top: 3.5rem;
  padding-right: 0;
  padding-left: 0;
  overflow: hidden;
}

@media (min-width: 768px) {
  .app-main {
    padding-left: 15rem;
  }
}

.wrapper {
  position: relative;
  margin: 0 auto;
}

.app-backdrop {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(61, 70, 79, 0.86);
  z-index: 1040;
}

@media (max-width: 767.98px) {
  .app-backdrop.show {
    display: block;
    -webkit-animation: fadeInBackdrop 260ms ease forwards;
            animation: fadeInBackdrop 260ms ease forwards;
  }
}

@-webkit-keyframes fadeInBackdrop {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInBackdrop {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.el-example > .btn,
.el-example > .btn-group {
  margin: 4px 2px;
}

.app-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 3.5rem;
  background-color: #1b75bc;
  color: #ffffff;
  -webkit-box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
          box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
  z-index: 1030;
}

.app-header .btn-account {
  padding: 0 1rem;
  min-width: 3.5rem;
  min-height: 3.5rem;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.app-header .btn-account:hover {
  background-color: rgba(255, 255, 255, 0.12);
}

.app-header .btn-account:active, .app-header .btn-account:focus, .app-header .btn-account.focus, .app-header .btn-account.show {
  background-color: rgba(0, 0, 0, 0.12);
}

.app-header .btn-account .account-summary {
  max-width: 10rem;
}

.top-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 3.5rem;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}

.top-bar-brand {
  display: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 3.5rem;
  width: 15rem;
  padding: 0 .5rem 0 1rem;
  background-color: rgba(0, 0, 0, 0.12);
}

.top-bar-brand > a {
  color: rgba(255, 255, 255, 0.87);
}

.top-bar-brand > a:focus {
  color: #ffffff;
  text-decoration: none;
}

.top-bar-brand > a:hover {
  color: #ffffff;
  text-decoration: none;
}

@media (min-width: 768px) {
  .top-bar-brand {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.top-bar-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.top-bar-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0;
}

@media (min-width: 768px) {
  .top-bar-item {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

@media (min-width: 992px) {
  .top-bar-item {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

.top-bar-item-full {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.top-bar-item-right {
  margin-left: auto;
}

.top-bar-search {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .top-bar-search {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .top-bar-search {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .top-bar-search {
    max-width: 1140px;
  }
}

@media (max-width: 767.98px) {
  .top-bar-search {
    padding-right: 1rem;
    padding-left: 0.5rem;
  }
}

.top-bar-search .input-group {
  width: 100%;
  border-color: transparent;
}

.top-bar-search .input-group.focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.top-bar-search .input-group-search {
  background-color: rgba(255, 255, 255, 0.12);
}

.top-bar-search .input-group-search.focus {
  background-color: #ffffff;
}

.top-bar-search .input-group-search:not(.focus) .input-group-text,
.top-bar-search .input-group-search:not(.focus) .form-control,
.top-bar-search .input-group-search:not(.focus) .form-control::-webkit-input-placeholder {
  color: #ffffff;
}

.top-bar-search .input-group-search:not(.focus) .input-group-text,
.top-bar-search .input-group-search:not(.focus) .form-control,
.top-bar-search .input-group-search:not(.focus) .form-control:-ms-input-placeholder {
  color: #ffffff;
}

.top-bar-search .input-group-search:not(.focus) .input-group-text,
.top-bar-search .input-group-search:not(.focus) .form-control,
.top-bar-search .input-group-search:not(.focus) .form-control::-ms-input-placeholder {
  color: #ffffff;
}

.top-bar-search .input-group-search:not(.focus) .input-group-text,
.top-bar-search .input-group-search:not(.focus) .form-control,
.top-bar-search .input-group-search:not(.focus) .form-control::placeholder {
  color: #ffffff;
}

.header-nav {
  padding: 0 .5rem;
}

.header-nav > .nav-link,
.header-nav > .nav-item > .nav-link {
  padding: .5rem .75rem;
  margin: 0 .25rem;
  color: #ffffff;
  opacity: .7;
  border-radius: 0.2rem;
  border: 1px solid #1b75bc;
}

.header-nav > .nav-link:hover, .header-nav > .nav-link:focus, .header-nav > .nav-link.focus, .header-nav > .nav-link:active, .header-nav > .nav-link.active,
.header-nav > .nav-item > .nav-link:hover,
.header-nav > .nav-item > .nav-link:focus,
.header-nav > .nav-item > .nav-link.focus,
.header-nav > .nav-item > .nav-link:active,
.header-nav > .nav-item > .nav-link.active {
  color: #555a62;
  opacity: 1;
}

.header-nav > .nav-link:hover,
.header-nav > .nav-item > .nav-link:hover {
  border: 1px solid #555a62;
  background-color: rgba(255, 255, 255, 0.12);
}

.header-nav > .nav-link:focus, .header-nav > .nav-link.focus, .header-nav > .nav-link:active, .header-nav > .nav-link.active,
.header-nav > .nav-item > .nav-link:focus,
.header-nav > .nav-item > .nav-link.focus,
.header-nav > .nav-item > .nav-link:active,
.header-nav > .nav-item > .nav-link.active {
  background-color: rgba(0, 0, 0, 0.12);
}

.header-nav .show > .nav-link,
.header-nav .show > .nav-link:hover {
  color: #555a62;
  background-color: rgba(0, 0, 0, 0.12);
  border: 1px solid #555a62;
  opacity: 1;
}

.header-nav-dropdown {
  position: static;
}

@media (min-width: 576px) {
  .header-nav-dropdown {
    position: relative;
  }
}

@media (max-width: 575.98px) {
  .header-nav-dropdown .dropdown-arrow {
    top: auto;
    left: auto;
    margin-left: .75rem;
  }
}

.header-nav-dropdown .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

@media (max-width: 575.98px) {
  .header-nav-dropdown .dropdown-menu {
    margin-top: 0;
    left: 0 !important;
    right: 0 !important;
    width: 100%;
    border-radius: 0;
  }
}

.app-aside {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0;
  color: inherit;
  background-color: #F5F5F5;
  z-index: 1030;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transition: -webkit-transform 200ms ease-in-out;
  transition: -webkit-transform 200ms ease-in-out;
  transition: transform 200ms ease-in-out;
  transition: transform 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
}

.app-aside.has-open .aside-header > .hamburger {
  display: block;
}

@media (max-width: 767.98px) {
  .app-aside {
    top: 0;
    width: calc(100vw - 3.5rem);
    min-width: 15rem;
    max-width: 16.5rem;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
    z-index: 1050;
    border-right: 0;
  }
  .app-aside.has-open {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@media (min-width: 768px) {
  .app-aside {
    top: 3.5rem;
    width: 15rem;
    border-right: 1px solid #E9EAEB;
    z-index: 1;
  }
}

.aside-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
}

.aside-header {
  position: relative;
  display: block;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
          box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
  z-index: 3;
}

.aside-header > .hamburger {
  display: none;
  position: absolute;
  top: .75rem;
  right: -3rem;
}

.aside-header .btn-account {
  padding: 1rem;
  width: 100%;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.aside-header .btn-account:focus, .aside-header .btn-account.focus {
  background-color: inherit;
}

.aside-header .btn-account:active {
  background-color: #dee9f5;
}

.aside-header .btn-account[aria-expanded="true"]::before {
  content: '';
  height: 1px;
  background-color: #ffffff;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.aside-header .btn-account .account-name {
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.aside-header .btn-account .account-description {
  font-size: .875em;
}

.dropdown-aside {
  position: absolute;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.98);
  -webkit-box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
          box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
  z-index: 1;
}

.dropdown-aside.show {
  max-height: calc(100vh - 4.5rem);
  overflow: auto;
}

.dropdown-aside .dropdown-icon {
  text-align: center;
  width: 1.5rem;
  margin-right: .5em;
}

.aside-menu {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.aside-menu:not(.has-scrollable) {
  overflow-y: auto;
}

.page {
  min-height: 646px;
  min-height: calc(100vh - 3.5rem);
}

@media (min-width: 1200px) {
  .page.has-sidebar .page-inner {
    margin-right: 22.5rem;
  }
}

.page-navs {
  position: relative;
  display: block;
  padding-right: 1rem;
  padding-left: 1rem;
  -webkit-box-shadow: 0 1px 0 rgba(61, 70, 79, 0.125);
          box-shadow: 0 1px 0 rgba(61, 70, 79, 0.125);
  z-index: 1;
}

.page-navs.has-shadow {
  -webkit-box-shadow: 0 1px 3px 0 rgba(61, 70, 79, 0.15);
          box-shadow: 0 1px 3px 0 rgba(61, 70, 79, 0.15);
}

@media (min-width: 992px) {
  .page-navs {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

.page-navs > .navbar {
  padding-right: 0;
  padding-left: 0;
}

.page-navs > .nav-scroller,
.page-navs > .nav {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.page-navs > .nav-scroller .nav-tabs,
.page-navs > .nav-tabs {
  border-bottom: 0;
}

.page-cover {
  position: relative;
  padding: 2rem 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #E9EAEB;
  background-size: cover;
  -webkit-box-shadow: 0 1px 0 rgba(61, 70, 79, 0.125);
          box-shadow: 0 1px 0 rgba(61, 70, 79, 0.125);
}

@media (min-width: 576px) {
  .page-cover {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

@media (min-width: 992px) {
  .page-cover {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

.page-cover .cover-controls {
  margin-bottom: 1.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.page-cover .cover-controls:last-child {
  margin-bottom: 0px;
}

.page-cover .cover-controls .btn {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.page-cover .cover-controls .btn + .btn {
  margin-left: .5rem;
}

@media (min-width: 768px) {
  .page-cover .cover-controls {
    margin-bottom: 0;
    display: block;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  .page-cover .cover-controls-bottom {
    top: auto;
    bottom: .5rem;
  }
  .page-cover .cover-controls .btn + .btn {
    margin-left: 0;
  }
}

.page-message {
  padding: 2rem 1rem;
  text-align: center;
  background-color: #E9EAEB;
  border-bottom: 1px solid rgba(61, 70, 79, 0.05);
}

@media (min-width: 576px) {
  .page-message {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

@media (min-width: 992px) {
  .page-message {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

.page-message a:not(.btn) {
  text-decoration: underline;
}

.page-inner {
  padding: 1.5rem 0;
}

@media (min-width: 576px) {
  .page-inner {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

@media (min-width: 992px) {
  .page-inner {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

.page-inner-fill {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: calc(100vh - 3.5rem);
}

.page-title-bar,
.page-section {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .page-title-bar,
  .page-section {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .page-title-bar,
  .page-section {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .page-title-bar,
  .page-section {
    max-width: 1140px;
  }
}

.page-title-bar {
  margin-bottom: 1.5rem;
  padding: 0 1rem;
}

@media (min-width: 576px) {
  .page-title-bar {
    padding-right: 0;
    padding-left: 0;
  }
}

.page-title-bar .btn-toolbar {
  margin-left: -.75rem;
}

.page-title {
  margin-top: 0;
  font-size: 1.75rem;
}

.page-title > small {
  font-size: 1rem;
  color: #686F76;
}

@media (min-width: 768px) {
  .page-title > small {
    font-size: 0.875rem;
  }
}

.page-section {
  padding-right: 0;
  padding-left: 0;
}

@media (max-width: 575.98px) {
  .page-section > .card {
    border-radius: 0;
    border-width: 1px 0;
  }
}

.section-title {
  margin-top: 0;
  padding: 1rem 0px;
  font-size: 1rem;
}

.section-block {
  margin: 1rem 0;
}

@media (max-width: 575.98px) {
  .section-block {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.section-deck {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

@media (min-width: 1200px) {
  .section-deck {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
  .section-deck .card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-right: 10px;
    margin-left: 10px;
  }
}

@media (min-width: 992px) {
  .section-columns {
    -webkit-column-count: 2;
            column-count: 2;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }
  .section-columns > .section-item {
    display: inline-block;
    width: 100%;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    /* fix animations */
    -webkit-column-break-inside: avoid;
    /* fix columns */
  }
}

.page-sidebar {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-width: 22.5rem;
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: #F5F5F5;
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
  overflow: auto;
  z-index: 1029;
  -webkit-transition: -webkit-transform 200ms ease-in-out;
  transition: -webkit-transform 200ms ease-in-out;
  transition: transform 200ms ease-in-out;
  transition: transform 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
}

@media (min-width: 1200px) {
  .page-sidebar {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  .has-sidebar-open .page-sidebar {
    position: absolute;
    top: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }
}

@media (max-width: 575.98px) {
  .page-sidebar {
    max-width: 100%;
  }
}

.has-sidebar-open .page-sidebar {
  position: fixed;
  top: 3.5rem;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.page-sidebar-fixed {
  position: fixed;
  top: 3.5rem;
  overflow: auto;
}

.sidebar-backdrop {
  display: none;
  position: fixed;
  top: 3.5rem;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(61, 70, 79, 0.26);
  z-index: 1028;
}

@media (max-width: 1199.98px) {
  .has-sidebar-open .sidebar-backdrop {
    display: block;
    -webkit-animation: fadeInBackdrop 260ms ease forwards;
            animation: fadeInBackdrop 260ms ease forwards;
  }
}

.sidebar-header {
  position: relative;
  padding: .5rem 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 3.5rem;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
          box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
  z-index: 1;
}

.sidebar-header .breadcrumb {
  margin-bottom: 0;
}

.sidebar-title {
  margin: 1rem 0;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
}

.sidebar-section {
  padding: 20px;
}

.sidebar-section-fill {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow-y: auto;
}

.sidebar-section-fill:not(.sidebar-section) > .sidebar-title {
  margin-left: 1rem;
}

.sidebar-footer {
  position: relative;
  padding: .5rem;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 -1px 3px 0 rgba(61, 70, 79, 0.15);
          box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 -1px 3px 0 rgba(61, 70, 79, 0.15);
  z-index: 1;
}

.app-footer {
  margin: 1.5rem 0;
  padding: 0 1rem;
  text-align: center;
}

@media (min-width: 992px) {
  .app-footer {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

.rating-block {
  display: block;
}

.rating {
  position: relative;
  display: inline-block;
  font-size: 1.25rem;
  vertical-align: middle;
}

.rating-xs {
  font-size: .875rem;
}

.rating-sm {
  font-size: 1rem;
}

.rating-lg {
  font-size: 1.5rem;
}

.rating > label,
.rating > input[type="radio"],
.rating > input[type="checkbox"] {
  float: right;
  cursor: pointer;
}

.rating > label {
  margin-bottom: 0;
  display: inline;
  color: #E9EAEB;
  line-height: 1;
}

.rating > label:hover {
  color: #fbe3b9;
}

.rating > label:hover ~ label {
  color: #F7C46C;
}

.rating.has-readonly > label:hover {
  color: #E9EAEB;
}

.rating.has-readonly > label:hover ~ label {
  color: #E9EAEB;
}

.rating > input[type="radio"],
.rating > input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.rating > input[type="radio"]:checked ~ label,
.rating > input[type="checkbox"]:checked ~ label {
  color: #F7C46C;
}

.rating-blue > label:hover {
  color: #01b3f9;
}

.rating-blue > label:hover ~ label {
  color: #0179A8;
}

.rating-blue > input[type="radio"]:checked ~ label,
.rating-blue > input[type="checkbox"]:checked ~ label {
  color: #0179A8;
}

.rating-indigo > label:hover {
  color: #6496d1;
}

.rating-indigo > label:hover ~ label {
  color: #1b75bc;
}

.rating-indigo > input[type="radio"]:checked ~ label,
.rating-indigo > input[type="checkbox"]:checked ~ label {
  color: #1b75bc;
}

.rating-purple > label:hover {
  color: #8874b4;
}

.rating-purple > label:hover ~ label {
  color: #5F4B8B;
}

.rating-purple > input[type="radio"]:checked ~ label,
.rating-purple > input[type="checkbox"]:checked ~ label {
  color: #5F4B8B;
}

.rating-pink > label:hover {
  color: #d2a2c5;
}

.rating-pink > label:hover ~ label {
  color: #B76BA3;
}

.rating-pink > input[type="radio"]:checked ~ label,
.rating-pink > input[type="checkbox"]:checked ~ label {
  color: #B76BA3;
}

.rating-red > label:hover {
  color: #f3a9a1;
}

.rating-red > label:hover ~ label {
  color: #EA6759;
}

.rating-red > input[type="radio"]:checked ~ label,
.rating-red > input[type="checkbox"]:checked ~ label {
  color: #EA6759;
}

.rating-orange > label:hover {
  color: #f5c4a7;
}

.rating-orange > label:hover ~ label {
  color: #EC935E;
}

.rating-orange > input[type="radio"]:checked ~ label,
.rating-orange > input[type="checkbox"]:checked ~ label {
  color: #EC935E;
}

.rating-yellow > label:hover {
  color: #fbe3b9;
}

.rating-yellow > label:hover ~ label {
  color: #F7C46C;
}

.rating-yellow > input[type="radio"]:checked ~ label,
.rating-yellow > input[type="checkbox"]:checked ~ label {
  color: #F7C46C;
}

.rating-green > label:hover {
  color: #d4e3cb;
}

.rating-green > label:hover ~ label {
  color: #A7C796;
}

.rating-green > input[type="radio"]:checked ~ label,
.rating-green > input[type="checkbox"]:checked ~ label {
  color: #A7C796;
}

.rating-teal > label:hover {
  color: #00f4d0;
}

.rating-teal > label:hover ~ label {
  color: #00A28A;
}

.rating-teal > input[type="radio"]:checked ~ label,
.rating-teal > input[type="checkbox"]:checked ~ label {
  color: #00A28A;
}

.rating-cyan > label:hover {
  color: #5fafc9;
}

.rating-cyan > label:hover ~ label {
  color: #3686A0;
}

.rating-cyan > input[type="radio"]:checked ~ label,
.rating-cyan > input[type="checkbox"]:checked ~ label {
  color: #3686A0;
}

.rating-white > label:hover {
  color: white;
}

.rating-white > label:hover ~ label {
  color: #ffffff;
}

.rating-white > input[type="radio"]:checked ~ label,
.rating-white > input[type="checkbox"]:checked ~ label {
  color: #ffffff;
}

.rating-gray > label:hover {
  color: #91989e;
}

.rating-gray > label:hover ~ label {
  color: #686F76;
}

.rating-gray > input[type="radio"]:checked ~ label,
.rating-gray > input[type="checkbox"]:checked ~ label {
  color: #686F76;
}

.rating-gray-dark > label:hover {
  color: #616f7d;
}

.rating-gray-dark > label:hover ~ label {
  color: #3D464F;
}

.rating-gray-dark > input[type="radio"]:checked ~ label,
.rating-gray-dark > input[type="checkbox"]:checked ~ label {
  color: #3D464F;
}

.switcher-control {
  margin-bottom: 0;
  position: relative;
  width: 36px;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.switcher-input {
  display: none;
}

.switcher-indicator {
  margin-bottom: 0;
  display: block;
  overflow: hidden;
  cursor: pointer;
  height: 16px;
  padding: 0;
  line-height: 16px;
  border: 2px solid #E9EAEB;
  border-radius: 16px;
  background-color: #E9EAEB;
  -webkit-transition: background-color 150ms linear;
  transition: background-color 150ms linear;
}

.switcher-indicator:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  display: block;
  width: 16px;
  margin: 0px;
  background: #ffffff;
  right: 20px;
  border: 2px solid #E9EAEB;
  border-radius: 16px;
  -webkit-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}

.switcher-input:checked + .switcher-indicator,
.switcher-input:checked + .switcher-indicator:before {
  border-color: #1b75bc;
}

.switcher-input:checked + .switcher-indicator {
  background-color: #1b75bc;
}

.switcher-input:checked + .switcher-indicator:before {
  right: 0px;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.disabled > .switcher-input,
.switcher-input:disabled {
  pointer-events: none;
}

.disabled > .switcher-input + .switcher-indicator,
.switcher-input:disabled + .switcher-indicator {
  opacity: .65;
  cursor: default;
}

.switcher-control-success .switcher-input:checked + .switcher-indicator,
.switcher-control-success .switcher-input:checked + .switcher-indicator:before {
  border-color: #00A28A;
}

.switcher-control-success .switcher-input:checked + .switcher-indicator {
  background-color: #00A28A;
}

.switcher-control-danger .switcher-input:checked + .switcher-indicator,
.switcher-control-danger .switcher-input:checked + .switcher-indicator:before {
  border-color: #B76BA3;
}

.switcher-control-danger .switcher-input:checked + .switcher-indicator {
  background-color: #B76BA3;
}

.list-icons {
  padding-left: 0;
  margin-left: 1.5rem;
  list-style-type: none;
}

.list-icons > li {
  position: relative;
}

.list-icons > li:not(:last-child) {
  margin-bottom: .25rem;
}

.list-icons .list-icon {
  position: absolute;
  left: -1.5rem;
  width: 1rem;
  font-size: 1em;
  text-align: center;
}

.tile-wrapper {
  display: inline-block;
  text-align: center;
}

.tile-wrapper .tile {
  margin-bottom: .25rem;
}

.tile-wrapper .tile-peek {
  display: block;
  font-size: 0.875rem;
}

a.tile-wrapper .tile-peek {
  color: #686F76;
}

a.tile-wrapper:focus {
  text-decoration: none;
}

a.tile-wrapper:focus .tile-peek {
  color: #1b75bc;
}

a.tile-wrapper:hover {
  text-decoration: none;
}

a.tile-wrapper:hover .tile-peek {
  color: #1b75bc;
}

.tile {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  color: #686F76;
  font-weight: 600;
  font-size: 1rem;
  background-color: #E9EAEB;
  line-height: 2.25;
  text-align: center;
  vertical-align: middle;
  border-radius: 0.25rem;
}

.tile-img {
  position: relative;
  background-color: transparent;
  overflow: hidden;
}

.tile.bg-primary {
  color: #ffffff;
}

.tile.bg-secondary {
  color: #555a62;
}

.tile.bg-success {
  color: #ffffff;
}

.tile.bg-info {
  color: #ffffff;
}

.tile.bg-warning {
  color: #555a62;
}

.tile.bg-danger {
  color: #ffffff;
}

.tile.bg-light {
  color: #555a62;
}

.tile.bg-dark {
  color: #ffffff;
}

.tile-xxl {
  width: 8rem;
  height: 8rem;
  line-height: 8.5rem;
  font-size: 3rem;
}

.tile-xl {
  width: 5rem;
  height: 5rem;
  line-height: 5.25rem;
  font-size: 2rem;
}

.tile-lg {
  width: 3rem;
  height: 3rem;
  line-height: 3.25rem;
  font-size: 1.25rem;
}

.tile-md {
  width: 2.188rem;
  height: 2.188rem;
}

.tile-sm {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 0.75rem;
}

.tile-xs {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 0.75rem;
  line-height: 1.4rem;
}

.tile-xxl, .tile-xl, .tile-lg {
  border-radius: 0.3rem;
}

.tile-circle {
  border-radius: 4rem;
}

.tile > img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
  max-width: 200%;
}

a.tile {
  outline: 0;
}

a.tile:focus {
  color: #3D464F;
  text-decoration: none;
}

a.tile:hover {
  color: #3D464F;
  text-decoration: none;
}

a.tile.bg-primary:focus {
  color: #ffffff;
}

a.tile.bg-primary:hover {
  color: #ffffff;
}

a.tile.bg-secondary:focus {
  color: #555a62;
}

a.tile.bg-secondary:hover {
  color: #555a62;
}

a.tile.bg-success:focus {
  color: #ffffff;
}

a.tile.bg-success:hover {
  color: #ffffff;
}

a.tile.bg-info:focus {
  color: #ffffff;
}

a.tile.bg-info:hover {
  color: #ffffff;
}

a.tile.bg-warning:focus {
  color: #555a62;
}

a.tile.bg-warning:hover {
  color: #555a62;
}

a.tile.bg-danger:focus {
  color: #ffffff;
}

a.tile.bg-danger:hover {
  color: #ffffff;
}

a.tile.bg-light:focus {
  color: #555a62;
}

a.tile.bg-light:hover {
  color: #555a62;
}

a.tile.bg-dark:focus {
  color: #ffffff;
}

a.tile.bg-dark:hover {
  color: #ffffff;
}

.tile.bg-blue {
  color: #ffffff;
}

a.tile.bg-blue:focus {
  color: #ffffff;
}

a.tile.bg-blue:hover {
  color: #ffffff;
}

.tile.bg-indigo {
  color: #ffffff;
}

a.tile.bg-indigo:focus {
  color: #ffffff;
}

a.tile.bg-indigo:hover {
  color: #ffffff;
}

.tile.bg-purple {
  color: #ffffff;
}

a.tile.bg-purple:focus {
  color: #ffffff;
}

a.tile.bg-purple:hover {
  color: #ffffff;
}

.tile.bg-pink {
  color: #ffffff;
}

a.tile.bg-pink:focus {
  color: #ffffff;
}

a.tile.bg-pink:hover {
  color: #ffffff;
}

.tile.bg-red {
  color: #ffffff;
}

a.tile.bg-red:focus {
  color: #ffffff;
}

a.tile.bg-red:hover {
  color: #ffffff;
}

.tile.bg-orange {
  color: #555a62;
}

a.tile.bg-orange:focus {
  color: #555a62;
}

a.tile.bg-orange:hover {
  color: #555a62;
}

.tile.bg-yellow {
  color: #555a62;
}

a.tile.bg-yellow:focus {
  color: #555a62;
}

a.tile.bg-yellow:hover {
  color: #555a62;
}

.tile.bg-green {
  color: #555a62;
}

a.tile.bg-green:focus {
  color: #555a62;
}

a.tile.bg-green:hover {
  color: #555a62;
}

.tile.bg-teal {
  color: #ffffff;
}

a.tile.bg-teal:focus {
  color: #ffffff;
}

a.tile.bg-teal:hover {
  color: #ffffff;
}

.tile.bg-cyan {
  color: #ffffff;
}

a.tile.bg-cyan:focus {
  color: #ffffff;
}

a.tile.bg-cyan:hover {
  color: #ffffff;
}

.tile.bg-white {
  color: #555a62;
}

a.tile.bg-white:focus {
  color: #555a62;
}

a.tile.bg-white:hover {
  color: #555a62;
}

.tile.bg-gray {
  color: #ffffff;
}

a.tile.bg-gray:focus {
  color: #ffffff;
}

a.tile.bg-gray:hover {
  color: #ffffff;
}

.tile.bg-gray-dark {
  color: #ffffff;
}

a.tile.bg-gray-dark:focus {
  color: #ffffff;
}

a.tile.bg-gray-dark:hover {
  color: #ffffff;
}

.user-avatar {
  position: relative;
  margin-bottom: 0;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  font-size: 2rem;
  border-radius: 4rem;
  background-color: #ffffff;
  vertical-align: middle;
}

.user-avatar-xxl {
  width: 8rem;
  height: 8rem;
  font-size: 8rem;
}

.user-avatar-xl {
  width: 5rem;
  height: 5rem;
  font-size: 5rem;
}

.user-avatar-lg {
  width: 3rem;
  height: 3rem;
  font-size: 3rem;
}

.user-avatar-md {
  width: 2.188rem;
  height: 2.188rem;
  font-size: 2.188rem;
}

.user-avatar-sm {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem;
}

.user-avatar-xs {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}

.user-avatar.dropdown .dropdown-menu {
  min-width: 6rem;
}

.user-avatar.dropdown:hover .avatar-badge > .oi:before, .user-avatar.dropdown:focus .avatar-badge > .oi:before, .user-avatar.dropdown.focus .avatar-badge > .oi:before, .user-avatar.dropdown.show .avatar-badge > .oi:before {
  content: "\e02d";
}

.user-avatar.dropdown:hover .avatar-badge > .fa:before, .user-avatar.dropdown:focus .avatar-badge > .fa:before, .user-avatar.dropdown.focus .avatar-badge > .fa:before, .user-avatar.dropdown.show .avatar-badge > .fa:before {
  content: "\f0d7";
}

.user-avatar.dropdown:hover .offline, .user-avatar.dropdown:focus .offline, .user-avatar.dropdown.focus .offline, .user-avatar.dropdown.show .offline {
  color: #fff;
}

.user-avatar > a {
  color: inherit;
  outline: 0;
}

.user-avatar > a:focus {
  color: inherit;
  text-decoration: none;
}

.user-avatar > a:hover {
  color: inherit;
  text-decoration: none;
}

.user-avatar img,
.user-avatar canvas,
.user-avatar video,
.user-avatar audio {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4rem;
}

a.user-avatar:focus {
  outline: 0;
}

a.user-avatar:hover {
  outline: 0;
}

a.user-avatar:focus {
  -webkit-box-shadow: 0 0 0 3px #1b75bc;
          box-shadow: 0 0 0 3px #1b75bc;
}

.user-avatar-lg .avatar-badge {
  right: 5px;
}

.user-avatar-sm .avatar-badge,
.user-avatar-xs .avatar-badge {
  right: -2px;
}

.user-avatar-floated {
  margin-top: -2.5rem;
  -webkit-box-shadow: 0 0 0 2px #fff;
          box-shadow: 0 0 0 2px #fff;
  z-index: 2;
}

.user-avatar-floated.user-avatar-lg {
  margin-top: -.5rem;
}

.avatar-group {
  display: inline-block;
}

.avatar-group .user-avatar {
  -webkit-box-shadow: 0 0 0 2px #ffffff;
          box-shadow: 0 0 0 2px #ffffff;
}

.avatar-group .user-avatar:focus {
  z-index: 2;
}

.avatar-group .user-avatar:hover {
  z-index: 2;
}

.avatar-group .user-avatar + .user-avatar {
  display: inline-block;
  margin-left: -.5em;
}

.avatar-badge {
  position: absolute;
  right: 1px;
  bottom: 0;
  display: block;
  width: 8px;
  height: 8px;
  line-height: 1;
  text-align: center;
  font-size: 8px;
  color: #fff;
  background-color: #555a62;
  border-radius: 8px;
  -webkit-box-shadow: 0 0 0 2px #fff;
          box-shadow: 0 0 0 2px #fff;
  z-index: 2;
}

.avatar-badge.has-indicator {
  width: 10px;
  height: 10px;
}

.avatar-badge.online {
  background-color: #00A28A;
}

.avatar-badge.idle {
  background-color: #EC935E;
}

.avatar-badge.busy {
  background-color: #EA6759;
}

.avatar-badge.offline {
  color: #555a62;
  background-color: #555a62;
}

.avatar-badge .fa {
  line-height: 10px;
}

.hamburger {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0.5rem 0.5rem;
  cursor: pointer;
  height: 2.188rem;
  width: 2.188rem;
  -webkit-transition: background-color 300ms linear;
  transition: background-color 300ms linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  border-radius: 0.25rem;
  margin: 0;
  overflow: visible;
  outline: 0;
}

.hamburger-sm .hamburger-inner {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}

.hamburger:hover, .hamburger:focus, .hamburger.focus {
  background-color: rgba(255, 255, 255, 0.05);
  outline: 0;
}

.hamburger:active {
  background-color: rgba(19, 29, 40, 0.05);
}

.hamburger-box {
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 1.25rem;
  height: 2px;
  background-color: #fff;
  border-radius: 2px;
  position: absolute;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-timing-function: ease;
          transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -6px;
}

.hamburger-inner::after {
  bottom: -6px;
}

.hamburger-light .hamburger-inner,
.hamburger-light .hamburger-inner::before,
.hamburger-light .hamburger-inner::after {
  background-color: #3D464F;
}

/*
 * Arrow
 */
.hamburger-arrow.has-active .hamburger-inner::before {
  -webkit-transform: translate3d(-0.25rem, 1px, 0) rotate(-45deg) scale(0.7, 1);
          transform: translate3d(-0.25rem, 1px, 0) rotate(-45deg) scale(0.7, 1);
}

.hamburger-arrow.has-active .hamburger-inner::after {
  -webkit-transform: translate3d(-0.25rem, -1px, 0) rotate(45deg) scale(0.7, 1);
          transform: translate3d(-0.25rem, -1px, 0) rotate(45deg) scale(0.7, 1);
}

/*
 * Squeeze
 */
.hamburger-squeeze .hamburger-inner {
  -webkit-transition-duration: 0.075s;
          transition-duration: 0.075s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
          transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger-squeeze .hamburger-inner::before {
  -webkit-transition: top 0.075s 0.12s ease, opacity 0.075s ease;
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}

.hamburger-squeeze .hamburger-inner::after {
  -webkit-transition: bottom 0.075s 0.12s ease, -webkit-transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.075s 0.12s ease, -webkit-transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger-squeeze.has-active .hamburger-inner {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transition-delay: 0.12s;
          transition-delay: 0.12s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger-squeeze.has-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  -webkit-transition: top 0.075s ease, opacity 0.075s 0.12s ease;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}

.hamburger-squeeze.has-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  -webkit-transition: bottom 0.075s ease, -webkit-transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.075s ease, -webkit-transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.loading {
  padding: 1rem;
  text-align: center;
  color: #686F76;
  cursor: wait;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
}

@-webkit-keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.loader {
  display: inline-block;
  width: 1.75rem;
  height: 1.75rem;
  vertical-align: middle;
  border: solid 3px transparent;
  border-top-color: #1b75bc;
  border-left-color: #1b75bc;
  border-radius: 2rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transform-style: preserve-3d;
  -webkit-animation: loader 700ms linear infinite;
          animation: loader 700ms linear infinite;
}

.loader-sm {
  width: 1rem;
  height: 1rem;
  border-width: 2px;
}

.loader-lg {
  width: 3rem;
  height: 3rem;
  border-width: 4px;
}

.loader-light {
  border-top-color: #ffffff;
  border-left-color: #ffffff;
}

.card-figure {
  position: relative;
  padding: 10px;
  border-radius: 2px;
}

.card-figure.has-hoverable {
  -webkit-transition: -webkit-transform 200ms, -webkit-box-shadow 200ms;
  transition: -webkit-transform 200ms, -webkit-box-shadow 200ms;
  transition: transform 200ms, box-shadow 200ms;
  transition: transform 200ms, box-shadow 200ms, -webkit-transform 200ms, -webkit-box-shadow 200ms;
}

.card-figure.has-hoverable:focus {
  -webkit-transform: translate3d(0, -0.25rem, 0);
          transform: translate3d(0, -0.25rem, 0);
  -webkit-box-shadow: 0 5px 15px 0 rgba(61, 70, 79, 0.15);
          box-shadow: 0 5px 15px 0 rgba(61, 70, 79, 0.15);
}

.card-figure.has-hoverable:hover {
  -webkit-transform: translate3d(0, -0.25rem, 0);
          transform: translate3d(0, -0.25rem, 0);
  -webkit-box-shadow: 0 5px 15px 0 rgba(61, 70, 79, 0.15);
          box-shadow: 0 5px 15px 0 rgba(61, 70, 79, 0.15);
}

.card-figure:hover .img-link {
  opacity: 1;
}

.card-figure:hover .figure-action {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  z-index: 2;
}

.card-figure .figure {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 0px;
}

.card-figure .figure-caption {
  display: block;
  margin-top: 10px;
  font-size: 0.875rem;
  color: inherit;
}

@media (min-width: 768px) {
  .card-figure .figure-caption {
    font-size: 0.75rem;
  }
}

.figure-title {
  margin: 0 0 .125rem;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (min-width: 768px) {
  .figure-title {
    font-size: 0.875rem;
  }
}

.figure-title a:focus {
  color: #1b75bc;
  text-decoration: none;
}

.figure-title a:hover {
  color: #1b75bc;
  text-decoration: none;
}

.figure-img {
  position: relative;
  margin-bottom: 0;
  overflow: hidden;
}

.figure-img .img-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.96);
  opacity: 0;
  z-index: 2;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}

.figure-img .img-link .tile {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -1rem;
  margin-left: -1rem;
}

.figure-img .img-link:focus {
  opacity: 1;
  outline: 0;
}

.figure-img .img-link:hover {
  opacity: 1;
  outline: 0;
}

.figure-tools {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start;
  padding: .5rem;
  opacity: 0;
  z-index: 2;
  -webkit-transition: opacity 300ms ease;
  transition: opacity 300ms ease;
}

.figure-tools .tile {
  background-color: #ffffff;
}

.figure-tools .tile:focus {
  background-color: #F5F5F5;
}

.figure-tools .tile:hover {
  background-color: #F5F5F5;
}

.card-figure:hover .figure-tools {
  opacity: 1;
}

.figure-attachment {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: 8rem;
  background-color: #F5F5F5;
  overflow: hidden;
}

.figure-attachment > img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
  max-width: 200%;
}

.figure-action {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  opacity: 0;
  -webkit-transform: translate3d(0, 100%, 0);
          transform: translate3d(0, 100%, 0);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.figure-action .btn-block {
  border-radius: 0;
}

.figure-description {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 2.25rem .5rem;
  background-color: rgba(255, 255, 255, 0.96);
  opacity: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  z-index: 1;
}

.card-figure:hover .figure-description {
  opacity: 1;
}

.outbound-link {
  margin-bottom: 1.25rem;
  padding: 1.25rem;
  display: block;
  color: #1b75bc;
  border: 1px solid rgba(61, 70, 79, 0.125);
  border-radius: 0.25rem;
}

.outbound-link:focus {
  color: #1b75bc;
  text-decoration: none;
  border-color: #555a62;
}

.outbound-link:hover {
  color: #1b75bc;
  text-decoration: none;
  border-color: #555a62;
}

.outbound-link .outbound-title {
  margin: 0;
  font-size: 1rem;
  font-weight: normal;
}

.outbound-link .outbound-text {
  margin-bottom: 0;
  font-size: 0.875rem;
}

.steps {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 1.25rem 0;
}

.steps ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  padding-left: 0;
  list-style: none;
  background-color: #E9EAEB;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  border-radius: 999px;
}

.steps li {
  position: relative;
  overflow: hidden;
  max-width: 100%;
}

.steps li:first-child:before {
  display: none;
}

.steps li:first-child a {
  border-top-left-radius: 999px;
  border-bottom-left-radius: 999px;
}

.steps li:last-child a {
  border-top-right-radius: 999px;
  border-bottom-right-radius: 999px;
}

.steps li:before {
  content: '';
  position: absolute;
  top: 2px;
  left: -0.875rem;
  z-index: 1;
  display: block;
  height: 1.75rem;
  width: 1.75rem;
  border: 2px solid #fff;
  border-top-right-radius: .5rem;
  background-color: #E9EAEB;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.steps li:hover + li:before {
  background-color: #e1e2e4;
}

.steps li:hover a {
  background-color: #e1e2e4;
  text-decoration: none;
}

.steps a {
  display: block;
  padding: 5px 0.875rem 6px 1.75rem;
  text-align: center;
  color: #1b75bc;
  line-height: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.steps a:focus {
  text-decoration: none;
}

.steps a:hover {
  text-decoration: none;
}

.steps a > span:last-child {
  margin-left: 5px;
}

.step-indicator {
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1rem;
  color: #fff;
  text-align: center;
  background-color: #555a62;
  line-height: 1.25rem;
  border-radius: 1.25rem;
}

.step-indicator-icon {
  background-color: transparent;
  color: #686F76;
}

.step-indicator-icon > .fa {
  margin-top: 2px;
}

.steps .active + li:before {
  background-color: #1b75bc;
}

.steps .active a {
  background-color: #1b75bc;
  color: #fff;
}

.steps .active .step-indicator {
  color: #fff;
}

.steps .active:hover + li:before {
  background-color: #3165a4;
}

.steps .active:hover a {
  background-color: #3165a4;
  color: #fff;
}

.steps .success + li:before {
  background-color: #00A28A;
}

.steps .success a {
  background-color: #00A28A;
  color: #fff;
}

.steps .success .step-indicator {
  color: #fff;
}

.steps .success:hover + li:before {
  background-color: #00937d;
}

.steps .success:hover a {
  background-color: #00937d;
  color: #fff;
}

.steps .error + li:before {
  background-color: #EA6759;
}

.steps .error a {
  background-color: #EA6759;
  color: #fff;
}

.steps .error .step-indicator {
  color: #fff;
}

.steps .error:hover + li:before {
  background-color: #e85b4b;
}

.steps .error:hover a {
  background-color: #e85b4b;
  color: #fff;
}

.steps-float {
  margin: 0;
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0);
}

.todo-header {
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: 600;
  color: #686F76;
}

.todo {
  margin-bottom: .5rem;
}

.todo + .todo-header {
  margin-top: 1.25rem;
}

.todo .custom-control {
  padding-left: 2rem;
}

.todo .custom-control-input:checked + .custom-control-label {
  color: #686F76;
  text-decoration: line-through;
}

.progress-list {
  position: relative;
  margin-bottom: 1.25rem;
  margin-left: 0;
  padding-left: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.progress-list > li {
  position: relative;
  width: 100%;
  text-align: center;
}

.progress-list > li::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -1px;
  width: 100%;
  height: 3px;
  background-color: rgba(61, 70, 79, 0.125);
}

.progress-list > li:last-child::before {
  display: none;
}

.progress-list > li > button {
  padding: 0;
  position: relative;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-color: #E9EAEB;
  color: rgba(61, 70, 79, 0.125);
  border: 4px solid #ffffff;
  vertical-align: middle;
  border-radius: 1rem;
  line-height: 1;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  white-space: normal;
  z-index: 1;
}

.progress-list > li > button:focus, .progress-list > li > button:active {
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px #1b75bc;
          box-shadow: 0 0 0 2px #1b75bc;
}

.progress-list > .error > button,
.progress-list > .success > button {
  width: 24px;
  height: 24px;
}

.progress-list .progress-indicator {
  display: none;
  width: 1rem;
  height: 1rem;
  background-size: cover;
  background-repeat: no-repeat;
}

.progress-list .progress-label {
  display: inline-block;
  position: absolute;
  top: 1.75rem;
  left: 50%;
  -webkit-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0);
  font-size: 0.875rem;
  color: #686F76;
}

.progress-list > .active::before {
  background-color: #1b75bc;
}

.progress-list > .active > button {
  color: #1b75bc;
  background-color: #1b75bc;
  border-color: #1b75bc;
}

.progress-list > .active > button:focus, .progress-list > .active > button:active {
  -webkit-box-shadow: 0 0 0 2px #1b75bc;
          box-shadow: 0 0 0 2px #1b75bc;
}

.progress-list > .active .progress-indicator {
  color: #1b75bc;
}

.progress-list > .active::before {
  background-color: rgba(61, 70, 79, 0.125);
}

.progress-list > .active > button {
  background-color: #ffffff;
}

.progress-list > .active .progress-label {
  color: #1b75bc;
}

.progress-list > .success::before {
  background-color: #1b75bc;
}

.progress-list > .success > button {
  color: #1b75bc;
  background-color: #ffffff;
  border-color: #ffffff;
}

.progress-list > .success > button:focus, .progress-list > .success > button:active {
  -webkit-box-shadow: 0 0 0 2px #1b75bc;
          box-shadow: 0 0 0 2px #1b75bc;
}

.progress-list > .success .progress-indicator {
  color: #1b75bc;
}

.progress-list > .success .progress-label {
  color: #1b75bc;
}

.progress-list > .error::before {
  background-color: #EA6759;
}

.progress-list > .error > button {
  color: #EA6759;
  background-color: #ffffff;
  border-color: #ffffff;
}

.progress-list > .error > button:focus, .progress-list > .error > button:active {
  -webkit-box-shadow: 0 0 0 2px #EA6759;
          box-shadow: 0 0 0 2px #EA6759;
}

.progress-list > .error .progress-indicator {
  color: #EA6759;
}

.progress-list > .error::before {
  background-color: rgba(61, 70, 79, 0.125);
}

.progress-list > .active .progress-indicator,
.progress-list > .success .progress-indicator,
.progress-list > .error .progress-indicator {
  display: inline-block;
}

.progress-list > .success .progress-indicator {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%2300A28A' viewBox='0 0 24 24'%3e%3cpath d='M12 .9C5.9.9.9 5.9.9 12s5 11.1 11.1 11.1 11.1-5 11.1-11.1S18.1.9 12 .9zm6.2 8.3l-7.1 7.2c-.3.3-.7.3-1 0l-3.9-3.9c-.2-.3-.2-.8 0-1.1l1-1c.3-.2.8-.2 1.1 0l2 2.1c.2.2.5.2.7 0l5.2-5.3c.2-.3.7-.3 1 0l1 1c.3.2.3.7 0 1z'%3e%3c/path%3e%3c/svg%3e");
}

.progress-list > .error .progress-indicator {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23EA6759' viewBox='0 0 24 24'%3e%3cpath xmlns='http://www.w3.org/2000/svg' d='M12 .9C5.9.9.9 5.9.9 12s5 11.1 11.1 11.1 11.1-5 11.1-11.1S18.1.9 12 .9zm2.3 11.5l3.6 3.6c.1.2.1.4 0 .6l-1.3 1.3c-.2.2-.5.2-.7 0l-3.6-3.6c-.2-.2-.4-.2-.6 0l-3.6 3.6c-.2.2-.5.2-.7 0l-1.3-1.3c-.1-.2-.1-.4 0-.6l3.6-3.6c.2-.2.2-.5 0-.7L6.1 8.1c-.2-.2-.2-.5 0-.7l1.3-1.3c.2-.1.4-.1.6 0l3.7 3.7c.2.2.4.2.6 0l3.6-3.6c.2-.2.5-.2.7 0l1.3 1.3c.1.2.1.4 0 .6l-3.6 3.6c-.2.2-.2.5 0 .7z'%3e%3c/path%3e%3c/svg%3e");
}

.visual-picker {
  position: relative;
  display: inline-block;
  margin-bottom: 1rem;
}

.visual-picker-sm .visual-picker-figure {
  width: 4rem;
  height: 4rem;
}

.visual-picker-sm .visual-picker-figure:after {
  content: '';
}

.visual-picker-lg .visual-picker-figure {
  width: 12rem;
  height: 12rem;
}

.visual-picker-lg .visual-picker-figure:after {
  top: 1rem;
  right: 1rem;
  font-size: 1.25rem;
}

.visual-picker-fluid {
  width: 100%;
}

.visual-picker-fluid .visual-picker-figure {
  width: 100%;
}

.visual-picker.has-peek {
  padding-bottom: 1.5rem;
}

.visual-picker.has-peek .visual-picker-figure {
  margin-bottom: .5em;
}

.visual-picker:focus .visual-picker-figure {
  -webkit-box-shadow: inset 0 0 0 2px #1b75bc, 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
          box-shadow: inset 0 0 0 2px #1b75bc, 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
}

.visual-picker:hover .visual-picker-figure {
  -webkit-box-shadow: inset 0 0 0 2px #1b75bc, 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
          box-shadow: inset 0 0 0 2px #1b75bc, 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
}

.visual-picker input[type="checkbox"],
.visual-picker input[type="radio"] {
  width: 1px;
  height: 1px;
  border: 0;
  clip: rect(0 0 0 0);
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

.visual-picker input[type="checkbox"]:checked ~ .visual-picker-figure,
.visual-picker input[type="radio"]:checked ~ .visual-picker-figure {
  -webkit-box-shadow: inset 0 0 0 2px #1b75bc, 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
          box-shadow: inset 0 0 0 2px #1b75bc, 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
}

.visual-picker input[type="checkbox"]:checked ~ .visual-picker-figure:after,
.visual-picker input[type="radio"]:checked ~ .visual-picker-figure:after {
  opacity: 1;
}

.visual-picker input[type="checkbox"]:focus ~ .visual-picker-figure,
.visual-picker input[type="radio"]:focus ~ .visual-picker-figure {
  -webkit-box-shadow: inset 0 0 0 2px #1b75bc, 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
          box-shadow: inset 0 0 0 2px #1b75bc, 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
}

.visual-picker-figure {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 8rem;
  height: 8rem;
  color: #1b75bc;
  background-color: #ffffff;
  border-radius: 0.25rem;
  cursor: pointer;
  -webkit-box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
          box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
  -webkit-transition: -webkit-box-shadow 200ms;
  transition: -webkit-box-shadow 200ms;
  transition: box-shadow 200ms;
  transition: box-shadow 200ms, -webkit-box-shadow 200ms;
}

.visual-picker-figure:after {
  font-family: 'Font Awesome 5 Free';
  content: '\f058';
  position: absolute;
  top: .5rem;
  right: .5rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-weight: 900;
  font-size: 1rem;
  font-variant: normal;
  color: #1b75bc;
  text-rendering: auto;
  line-height: 1;
  opacity: 0;
  -webkit-transition: opacity 200ms;
  transition: opacity 200ms;
}

.visual-picker-content {
  display: block;
  padding: 0 .5rem;
  font-size: 0.75rem;
  text-align: center;
}

.visual-picker-peek {
  position: absolute;
  left: 0;
  right: 0;
  display: block;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

a.visual-picker {
  color: #1b75bc;
}

a.visual-picker:focus {
  outline: 0;
  color: #1b75bc;
  text-decoration: none;
}

a.visual-picker:hover {
  outline: 0;
  color: #1b75bc;
  text-decoration: none;
}

.publisher {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.publisher .publisher-label,
.publisher .publisher-tools {
  display: none;
}

.publisher.focus {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.publisher.focus .publisher-input,
.publisher.focus .publisher-label,
.publisher.focus .publisher-tools {
  display: block;
}

.publisher.focus .publisher-input {
  margin-bottom: .5em;
  padding-right: 0;
  width: 100%;
}

.publisher.focus .publisher-input .form-control {
  height: auto;
}

.publisher .btn-link {
  color: #686F76;
}

.publisher .btn-link:focus {
  color: #3D464F;
}

.publisher .btn-link:hover {
  color: #3D464F;
}

.publisher .user-avatar {
  width: 2.25rem;
  height: 2.25rem;
}

@media (min-width: 768px) {
  .publisher .user-avatar {
    width: 2.188rem;
    height: 2.188rem;
  }
}

.publisher-input {
  padding-right: 12px;
  width: 100%;
}

.publisher-input .form-control {
  height: 2.25rem;
  resize: none;
}

@media (min-width: 768px) {
  .publisher-input .form-control {
    height: 2.188rem;
  }
}

.publisher-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.publisher-alt {
  border: 1px solid #D4D5D7;
  border-radius: 0.25rem;
  background-color: #ffffff;
}

.publisher-alt .publisher-input {
  margin-bottom: 0;
}

.publisher-alt .publisher-input {
  padding-right: 0;
}

.publisher-alt .publisher-input .form-control {
  border: 0;
}

.publisher-alt .publisher-input .form-control:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.publisher-alt .publisher-actions {
  display: none;
  padding: 0 1em .75em 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.publisher-alt .publisher-tools {
  padding: 0 .5em .75em;
}

.publisher-alt.focus {
  border-color: #1b75bc;
  -webkit-box-shadow: 0 0 0 1px #1b75bc;
          box-shadow: 0 0 0 1px #1b75bc;
}

.publisher-alt.focus .publisher-input {
  margin-top: 0;
}

.publisher-alt.focus .publisher-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.timeline {
  margin: 0;
  padding: 0;
  display: block;
}

@media (min-width: 768px) {
  .timeline {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.timeline-heading {
  margin-top: .25rem;
  font-size: 1em;
}

.timeline-heading .custom-control-inline {
  vertical-align: middle;
  margin-right: 0;
  margin-top: -4px;
}

@media (min-width: 768px) {
  .timeline-heading .custom-control-inline {
    margin-top: 0;
  }
}

.timeline-item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.timeline-item::after {
  display: block;
  clear: both;
  content: "";
}

.timeline-item:first-child .timeline-figure,
.timeline-item:first-child .timeline-body {
  padding-top: 0;
}

.timeline-item:last-child .timeline-figure,
.timeline-item:last-child .timeline-body {
  margin-bottom: 1.25rem;
}

.timeline-figure {
  position: relative;
  padding: .5rem 0;
}

.timeline-figure::before {
  content: '';
  position: absolute;
  display: block;
  height: 100%;
  top: 1rem;
  left: 50%;
  margin-left: -1px;
  border-left: 2px solid rgba(61, 70, 79, 0.125);
}

.timeline-figure .tile {
  -webkit-box-shadow: 0 0 0 2px #F5F5F5;
          box-shadow: 0 0 0 2px #F5F5F5;
}

.timeline-fluid .timeline-figure {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.timeline-fluid .timeline-figure::before {
  display: none;
}

.timeline-body {
  width: 100%;
  margin-left: 1.25rem;
  padding: .5rem 0;
}

.timeline-fluid .timeline-body {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.timeline-date {
  font-size: 0.75rem;
  color: #686F76;
}

.conversations {
  position: relative;
  padding: 1rem;
}

.conversation-list {
  margin-bottom: 0;
  padding-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  list-style: none;
}

.conversation-list .log-divider {
  font-size: 0.75rem;
}

.conversation-list > li:focus .conversation-actions {
  opacity: 1;
}

.conversation-list > li:hover .conversation-actions {
  opacity: 1;
}

.conversation-inbound,
.conversation-outbound {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.conversation-inbound:not(:first-child),
.conversation-outbound:not(:first-child) {
  margin-top: 1rem;
}

.conversation-inbound + .conversation-faux,
.conversation-outbound + .conversation-faux {
  margin-top: 0.375rem;
}

.conversation-inbound .conversation-avatar {
  margin-right: .5rem;
}

.conversation-inbound .conversation-message-skip-avatar {
  padding-left: 2.5rem;
}

.conversation-inbound .conversation-message-text {
  color: #1b75bc;
  background-color: #F5F5F5;
  border-radius: 1rem 1rem 1rem 0;
}

.conversation-outbound {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.conversation-outbound .conversation-avatar {
  margin-left: .5rem;
}

.conversation-outbound .conversation-message-skip-avatar {
  padding-right: 2.5rem;
}

.conversation-outbound .conversation-message-text {
  color: #ffffff;
  background-color: #1b75bc;
  border-radius: 1rem 1rem 0;
}

.conversation-outbound .conversation-message-text.has-attachment a:not(.btn) {
  color: #ffffff;
  opacity: .87;
}

.conversation-outbound .conversation-message-text.has-attachment a:not(.btn):focus {
  color: #ffffff;
  opacity: 1;
}

.conversation-outbound .conversation-message-text.has-attachment a:not(.btn):hover {
  color: #ffffff;
  opacity: 1;
}

.conversation-avatar .user-avatar-sm {
  margin-top: .375rem;
}

.conversation-message {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.conversation-message .conversation-list {
  margin-top: 1rem;
}

.conversation-message .card-figure {
  margin-bottom: 0;
  width: 15rem;
  max-width: 43%;
}

.conversation-message-text {
  padding: .5rem;
  max-width: 480px;
  min-height: 2rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  -webkit-box-shadow: 0 1px 0.5px rgba(61, 70, 79, 0.12);
          box-shadow: 0 1px 0.5px rgba(61, 70, 79, 0.12);
}

.conversation-message-text.has-attachment .card-figure {
  width: 15rem;
  max-width: 100%;
}

.conversation-message-text.has-attachment a:not(.btn) {
  text-decoration: underline;
}

.conversation-message-text p {
  margin-bottom: .25em;
}

.conversation-meta {
  margin-top: .125rem;
  padding-left: .5rem;
  font-size: 0.75rem;
  color: #686F76;
}

.conversation-actions {
  padding: 0 .25rem;
  -ms-flex-item-align: start;
      align-self: start;
  opacity: 0;
  -webkit-transition: opacity 200ms;
  transition: opacity 200ms;
}

.feed {
  margin-bottom: 1.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
          box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
  border-radius: 0.25rem;
}

.feed > .feed-post .card-footer-item {
  -webkit-box-flex: 0;
      -ms-flex: 0;
          flex: 0;
}

.feed > .feed-post,
.feed > .feed-comments {
  margin-bottom: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.feed > .feed-post + .card,
.feed > .feed-comments + .card {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.feed > .feed-post .card-footer,
.feed > .feed-comments .card-footer {
  font-size: 0.875rem;
}

.feed-publisher {
  margin-bottom: 20px;
  padding: 1rem 1rem;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
          box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
}

@media (min-width: 576px) {
  .feed-publisher {
    border-radius: 0.25rem;
  }
}

.feed-publisher .form-control,
.feed-publisher .form-control:focus,
.feed-publisher .publisher,
.feed-publisher .publisher-alt,
.feed-publisher .publisher.focus,
.feed-publisher .publisher-alt.focus {
  background-color: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.feed-publisher .publisher-actions {
  padding: 0;
}

.feed-post:first-child:not(:last-child) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.feed-post .card-header {
  padding-right: 0;
  padding-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.feed-post .card-header {
  margin: 0 1rem;
}

.feed-post .account-summary {
  -ms-flex-item-align: start;
      align-self: flex-start;
}

.feed-post .account-summary .account-name {
  font-size: 1rem;
  line-height: 1.5;
}

.feed-attachments {
  margin-bottom: 1.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  overflow-x: auto;
}

.feed-attachments .card-figure {
  min-width: 300px;
  max-width: 43%;
}

.feed-attachments .card-figure:not(:last-child) {
  margin-right: .5rem;
}

.feed-summary {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 0.75rem;
}

.feed-summary-item {
  color: #1b75bc;
}

.feed-summary-item:not(:last-child) {
  margin-right: .5rem;
}

.feed-summary-item:focus {
  text-decoration: none;
}

.feed-summary-item:hover {
  text-decoration: none;
}

.feed-comments {
  border-top: 1px solid rgba(19, 29, 40, 0.125);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.feed-comments .card-header {
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: 0.75rem;
  font-weight: normal;
  border-bottom: 0;
}

.feed-comments .conversation-message-text {
  padding-top: 0;
  padding-bottom: 0;
  max-width: 100%;
  min-height: auto;
  background-color: #ffffff;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.feed-comments .conversation-message-text + .conversation-meta {
  margin-top: .5rem;
}

.feed-comments .conversation-meta {
  font-size: inherit;
}

.feed-comments .conversation-meta:first-child {
  margin-top: 0;
  margin-bottom: .5rem;
}

.board {
  display: block;
  padding: 1.5rem 1rem;
  white-space: nowrap;
  overflow-x: auto;
}

@media (min-width: 992px) {
  .board {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

.board-list {
  margin: 0;
  padding: 0;
  white-space: auto;
  overflow-x: initial;
}

.board-list .tasks {
  display: block;
  margin: 0;
  width: 100%;
  max-width: 100%;
}

.board-list .task-header {
  padding-right: 0;
  padding-left: 0;
  background-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}

@media (min-width: 576px) {
  .board-list .task-issue > .card {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .board-list .task-issue > .card > .card-header {
    width: 240px;
  }
  .board-list .task-issue > .card > .card-footer {
    border-top: 0;
  }
}

.tasks {
  display: inline-block;
  width: 18rem;
  padding: .5rem;
  vertical-align: top;
  background-color: #E9EAEB;
  -webkit-box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
          box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
  border-radius: 0.25rem;
}

.tasks:not(:last-child) {
  margin-right: 1.25rem;
}

.tasks.hover {
  background-color: #D4D5D7;
}

.tasks-action {
  display: inline-block;
  width: 18rem;
  padding: .5rem;
  vertical-align: top;
  background-color: #E9EAEB;
  -webkit-box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
          box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
  border-radius: 0.25rem;
}

.tasks-action:not(:last-child) {
  margin-right: 1.25rem;
}

.task-header {
  margin-bottom: .25rem;
  padding: 0 .25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 600;
}

.task-header .btn-reset {
  padding: .5rem;
}

.task-title {
  margin: 0;
  font-size: 1rem;
}

.task-body {
  min-height: 2rem;
}

.task-issue {
  border-radius: 0.25rem;
}

.task-issue:not(:last-child) {
  margin-bottom: .5rem;
}

.task-issue > .card {
  margin-bottom: 0;
}

.task-issue > .card > .card-header {
  border: 0;
  white-space: normal;
}

.task-issue > .card > .card-header > a {
  color: inherit;
}

.task-issue > .card > .card-header > a:focus {
  color: inherit;
  text-decoration: none;
}

.task-issue > .card > .card-header > a:hover {
  color: inherit;
  text-decoration: none;
}

.btn-task {
  display: block;
  width: 100%;
  text-align: left;
}

.btn-task:not(:first-child) {
  margin-top: .5rem;
}

.task-primary {
  border-left: 4px solid #1b75bc;
}

.task-secondary {
  border-left: 4px solid #F5F5F5;
}

.task-success {
  border-left: 4px solid #00A28A;
}

.task-info {
  border-left: 4px solid #0179A8;
}

.task-warning {
  border-left: 4px solid #F7C46C;
}

.task-danger {
  border-left: 4px solid #B76BA3;
}

.task-light {
  border-left: 4px solid #F5F5F5;
}

.task-dark {
  border-left: 4px solid #3D464F;
}

.task-blue {
  border-left: 4px solid #0179A8;
}

.task-indigo {
  border-left: 4px solid #1b75bc;
}

.task-purple {
  border-left: 4px solid #5F4B8B;
}

.task-pink {
  border-left: 4px solid #B76BA3;
}

.task-red {
  border-left: 4px solid #EA6759;
}

.task-orange {
  border-left: 4px solid #EC935E;
}

.task-yellow {
  border-left: 4px solid #F7C46C;
}

.task-green {
  border-left: 4px solid #A7C796;
}

.task-teal {
  border-left: 4px solid #00A28A;
}

.task-cyan {
  border-left: 4px solid #3686A0;
}

.task-white {
  border-left: 4px solid #ffffff;
}

.task-gray {
  border-left: 4px solid #686F76;
}

.task-gray-dark {
  border-left: 4px solid #3D464F;
}

.metric-row {
  margin-bottom: 1.25rem;
  border-radius: 0.25rem;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

.metric-row .metric {
  margin: .5rem 0;
  min-height: 8.5rem;
}

.metric-flush {
  margin: .5rem -1px .5rem 1px;
}

@media (max-width: 575.98px) {
  .metric-flush {
    display: block;
  }
  .metric-flush > .col,
  .metric-flush > [class="col-*"] {
    margin-top: -1px;
  }
}

.metric-flush .metric {
  margin: 0;
  border-radius: 0;
}

.metric-flush > .col,
.metric-flush > [class="col-*"] {
  margin-left: -1px;
  padding-right: 0;
  padding-left: 0;
}

.metric-flush > .col:first-child > .metric,
.metric-flush > [class="col-*"]:first-child > .metric {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.metric-flush > .col:last-child > .metric,
.metric-flush > [class="col-*"]:last-child > .metric {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

@media (min-width: 576px) {
  .metric-flush > .col:first-child > .metric,
  .metric-flush > [class="col-*"]:first-child > .metric {
    border-top-right-radius: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .metric-flush > .col:last-child > .metric,
  .metric-flush > [class="col-*"]:last-child > .metric {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
}

.metric {
  position: relative;
  padding: 1rem 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  max-width: 100%;
  border-radius: 0.25rem;
  cursor: default;
}

@media (min-width: 576px) {
  .metric {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
  }
}

a.metric,
.metric-hoverable {
  color: inherit;
  outline: 0;
  cursor: pointer;
}

a.metric:focus,
.metric-hoverable:focus {
  color: inherit;
  text-decoration: none;
  border: 1px solid #D4D5D7;
}

a.metric:hover,
.metric-hoverable:hover {
  color: inherit;
  text-decoration: none;
  border: 1px solid #D4D5D7;
}

.metric-bordered {
  border: 1px solid #D4D5D7;
}

.metric-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #686F76;
  white-space: nowrap;
}

.metric-label:first-child {
  margin-bottom: .5rem;
}

.metric-label:last-child {
  margin-top: .5rem;
  margin-bottom: 0;
}

.metric-value {
  margin-bottom: 0;
  line-height: 1;
  white-space: nowrap;
}

.metric-value .value {
  vertical-align: middle;
}

.metric-value > sup,
.metric-value > sub {
  color: #555a62;
  font-size: .5em;
}

.metric-value > sup {
  top: -.75em;
}

.metric-value > sub {
  bottom: 0;
}

.metric-badge {
  position: relative;
  top: -.5rem;
}

.card-metric {
  text-align: center;
  background-color: #ffffff;
  border: none;
  border-radius: 0.25rem;
  -webkit-box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
          box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
}

.countdown {
  margin-bottom: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  font-weight: 600;
}

@media (max-width: 575.98px) {
  .countdown {
    font-size: 3rem;
    border-radius: 0.25rem;
  }
}

.countdown-item {
  padding: 1rem 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.countdown-item > small {
  font-size: 0.875rem;
  line-height: 0.875rem;
  font-weight: inherit;
}

@media (max-width: 575.98px) {
  .countdown-item {
    padding: 1rem;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
    width: 50%;
  }
}

.empty-state {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.empty-state-fullpage {
  min-height: 100%;
}

.empty-state-container {
  position: relative;
  padding: 0 1rem;
  max-width: 640px;
  text-align: center;
  z-index: 1;
}

.state-figure {
  margin-bottom: 1.5rem;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.state-header {
  margin-top: 0;
  margin-bottom: 1.25rem;
}

.state-description {
  margin-bottom: 1.25rem;
}

.state-action {
  margin-bottom: .5rem;
}

.auth {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 100%;
}

.auth-header {
  position: relative;
  padding: 2rem 1.25rem 0;
  width: 100%;
  background-color: #1b75bc;
  color: #ffffff;
  text-align: center;
  background-size: cover;
}

@media (min-width: 768px) {
  .auth-header {
    margin-bottom: -3rem;
    padding-top: 6rem;
    padding-bottom: 3rem;
  }
  .auth-header .container {
    max-width: 420px;
  }
}

.auth-header a {
  color: #F7C46C;
  text-decoration: underline;
}

.auth-header a:focus {
  color: #d3a961;
}

.auth-header a:hover {
  color: #d3a961;
}

.auth-header canvas {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
}

.auth-form {
  position: relative;
  padding: 2rem 1.25rem 0;
  width: 100%;
  z-index: 1;
}

@media (min-width: 768px) {
  .auth-form {
    padding: 3rem 2rem 2rem;
    max-width: 420px;
    border-radius: 0.25rem;
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
            box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
  }
}

.auth-form-reflow {
  padding: 2rem 1rem 0;
  background-color: transparent;
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.auth-form .link {
  text-decoration: underline;
}

.auth-floated {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}

.auth-floated .auth-form {
  max-width: 100%;
  border-radius: 0;
  padding-top: 2rem;
  text-align: center;
  z-index: 2;
}

@media (min-width: 992px) {
  .auth-floated .auth-form {
    max-width: 33.333333%;
  }
}

.auth-floated .auth-announcement {
  display: none;
  padding: 6rem 3rem;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: center;
  color: #ffffff;
  background-color: #1b75bc;
  background-size: cover;
}

@media (min-width: 992px) {
  .auth-floated .auth-announcement {
    display: block;
  }
}

.auth-floated .announcement-title {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.auth-floated .announcement-body {
  position: relative;
  z-index: 2;
}

.auth-footer {
  padding: 1rem;
  color: #686F76;
}

@media (min-width: 768px) {
  .auth-footer {
    max-width: 420px;
  }
}

.list-group-messages.list-group-bordered .list-group-item {
  border-color: rgba(61, 70, 79, 0.075);
}

.list-group-messages .list-group-item {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  background-color: transparent;
}

.list-group-messages .list-group-item-figure,
.list-group-messages .list-group-item-body {
  -ms-flex-item-align: start;
      align-self: start;
}

.list-group-messages .unread .list-group-item-subtitle,
.list-group-messages .unread .list-group-item-title {
  font-weight: 600;
}

.list-group-messages .read .list-group-item-subtitle,
.list-group-messages .read .list-group-item-title {
  color: #686F76;
}

.message {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
}

.message-header {
  position: relative;
  padding: .5rem .5rem .5rem .25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 3.5rem;
  background-color: #ffffff;
  -webkit-box-shadow: 0 1px 0 0 rgba(61, 70, 79, 0.075);
          box-shadow: 0 1px 0 0 rgba(61, 70, 79, 0.075);
  z-index: 1;
}

@media (min-width: 768px) {
  .message-header {
    padding: .5rem;
  }
}

.message-header-actions {
  margin-left: auto;
  min-width: 92px;
  text-align: right;
}

.message-title {
  margin: 0 .5rem;
  font-size: 1rem;
  line-height: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.message-body {
  padding: 0;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow-y: auto;
}

@media (min-width: 576px) {
  .message-body {
    padding: 1.5rem 1rem;
  }
}

@media (min-width: 992px) {
  .message-body {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

.message-publisher {
  position: relative;
  padding: .5rem;
  background-color: #ffffff;
  -webkit-box-shadow: 0 -1px 0 0 rgba(61, 70, 79, 0.075);
          box-shadow: 0 -1px 0 0 rgba(61, 70, 79, 0.075);
  z-index: 1;
}

.message-publisher .input-group {
  border: 0;
  background-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.message-publisher .input-group.focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
